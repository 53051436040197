import React, { useCallback, useRef, useEffect, useState } from 'react';

import { FiMail } from 'react-icons/fi';
import { Container } from './styles';
import Vimeo from '../Vimeo';
import { Button } from '../Button/styles';
import { useModal } from '../../hooks/Modal';
import SendEmailToPoster from '../SendEmailToPoster';
import { Modal } from '../ModalContainer/styles';
import ChatRoom from '../Chat';
import { useLanguage } from '../../hooks/Language';
import Youtube from '../Youtube';

interface IPoster {
  id : number;
  title?: string;
  author?: string;
  image?: string;
  video_type?: number;
  video_id: string;
  co_authors?: string;
  category?: string;
  have_video?: number;
  formatted_date?: string;
  formatted_start_hour?: string;
  room_id?: string;
  hash?: string;
  description?: string;
}

interface IZoomPoster {
  posterItem: IPoster;
  closePoster: Function;
}

const PosterZoomComponent: React.FC<IZoomPoster> = ({
  posterItem,
  closePoster,
}) => {
  const { addModal } = useModal();
  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const [scaleImg, setScaleImg] = useState(100);
  const [typeImg, setTypeImg] = useState(1);
  const [active, setActive] = useState(false);
const {translate} = useLanguage();
  const elementsItems = [
    {
      name: 'Parte 1',
      type: 1,
      transform: ` scale(1) translateX(0) translateY(0)`,
      top: 20,
      left: 0.3,
      width: 33,
      height: 37,
    },
    {
      name: 'Parte 2',
      type: 1,
      transform: ` scale(1) translateX(0) translateY(0)`,
      top: 58,
      left: 0.3,
      width: 33,
      height: 42,
    },
    {
      name: 'Parte 3',
      type: 1,
      transform: ` scale(1) translateX(0) translateY(0)`,
      top: 20,
      left: 33,
      width: 33,
      height: 37,
    },
    {
      name: 'Parte 4',
      type: 1,
      transform: ` scale(1) translateX(0) translateY(0)`,
      top: 58,
      left: 33,
      width: 33,
      height: 42,
    },
    {
      name: 'Parte 5',
      type: 1,
      transform: ` scale(1) translateX(0) translateY(0)`,
      top: 20,
      left: 67,
      width: 33,
      height: 37,
    },
    {
      name: 'Parte 6',
      type: 1,
      transform: ` scale(1) translateX(0) translateY(0)`,
      top: 58,
      left: 67,
      width: 33,
      height: 42,
    },
  ];

  const [renderer, setRenderer] = useState(elementsItems);
  const [indexer, setIndexer] = useState([0, 2]);

  const zoom = (index: number, type: number) => {
    setIndexer([index, type]);
    const elements = [...elementsItems];
    const element = { ...elementsItems[index] };
    const { width, height, left, top } = element;

    const vetor = 95 / width;
    const getScale = vetor * 100;

    elements.map((elementIt, indexIt) => {
      elements[indexIt].type = 1;
    });

    element.type = type === 1 ? 2 : 1;
    // window.scrollTo(0, 0);

    elements.splice(index, 1, element);
    const tvetor = 1080 / 1920;
    const tX =
      left * vetor < 0 ? (left * vetor - 2.5) * -1 : left * vetor - 2.5;
    console.log(index, tX);

    setTranslateX(type === 1 ? left * (type === 1 ? 2.8 : 1) : 0);
    setTranslateY(type === 1 ? top * 0.5 * vetor : 0);
    setScaleImg(type === 1 ? getScale : 100);
    setTypeImg(type === 1 ? 2 : 1);
    setRenderer([...elements]);
  };

  const getPlayer = () => {
    const [index, type] = indexer;

    if (type === 2) {
      return (
        <>
          <button
            className="closeButton"
            type="button"
            onClick={() => closePoster()}
          >
            {translate('Fechar')}
          </button>
          {posterItem.have_video === 1 && (
            <button type="button" onClick={() => zoom(0, 1)}>
              {translate('Iniciar')}
            </button>
          )}
        </>
      );
    }

    const length = elementsItems.length - 1;

    if (index === 0 && length > 0) {
      return (
        <>
          <button
            className="closeButton"
            type="button"
            onClick={() => closePoster()}
          >
            {translate('Fechar')}
          </button>
          {posterItem.have_video === 1 && (
            <>
              <button type="button" onClick={() => zoom(0, 2)}>
              {translate('Tela Cheia')}
              </button>
              <button type="button" onClick={() => zoom(index + 1, 1)}>
              {translate('Próximo')}
              </button>
            </>
          )}
        </>
      );
    }

    if (index > 0 && length > index) {
      return (
        <>
          <button
            className="closeButton"
            type="button"
            onClick={() => closePoster()}
          >
            Fechar
          </button>
          {posterItem.have_video === 1 && (
            <>
              <button type="button" onClick={() => zoom(index - 1, 1)}>
              {translate('Anterior')}
              </button>
              <button type="button" onClick={() => zoom(0, 2)}>
              {translate('Tela Cheia')}
              </button>
              <button type="button" onClick={() => zoom(index + 1, 1)}>
              {translate('Próximo')}
              </button>
            </>
          )}
        </>
      );
    }

    if (index > 0 && length === index) {
      return (
        <>
          <button
            className="closeButton"
            type="button"
            onClick={() => closePoster()}
          >
            {translate('Fechar')}
          </button>
          {posterItem.have_video === 1 && (
            <>
              <button type="button" onClick={() => zoom(index - 1, 1)}>
              {translate('Anterior')}
              </button>
              <button type="button" onClick={() => zoom(0, 2)}>
              {translate('Tela Cheia')}
              </button>
              <button type="button" onClick={() => zoom(0, 1)}>
              {translate('Voltar ao início')}
              </button>
{' '}
            </>
          )}
        </>
      );
    }

    return <></>;
  };

  const showForm = () => {
    addModal({
      key: 'messageToPoster',
      title: translate('Enviar Mensagem'),
      content: <SendEmailToPoster hash={posterItem.hash} callback={() => {}} />,
    });
  };

  return (
    <Container>
      <div className="textContent">
        <p className="title">
          <strong>
            ID:
            <br />
          </strong>
          {posterItem.hash}
        </p>
        <p className="title">
          <strong>
          {translate('Título')}:
            <br />
          </strong>
          {posterItem.title}
        </p>
        <p>
          <strong>
          {translate('Autores')}:
            <br />
          </strong>
          {posterItem.author}
{' '}
{posterItem.co_authors && `, ${posterItem.co_authors}`}
        </p>
        <div>
          <Button type="button" onClick={() => setActive(true)}>
            <FiMail />
{' '}
{translate('Enviar Mensagem')}
</Button>
        </div>
        {posterItem.category !== 'Pôster Simples' && (
          <>
        
          </>
        )}
      </div>

      <div className="fixedplayer">{getPlayer()}</div>
<div className="posterPlace">
      {posterItem.have_video === 1 ? (
        <>
          <div style={{ display: 'flex' }}>
            <div className="sumario">
              {renderer.map((item, index) => {
                return (
                  <nav
                    onClick={() => zoom(index, item.type)}
                    key={`topico${index}`}
                  >
                    {item.name}
                  </nav>
                );
              })}
              <nav onClick={() => zoom(0, 2)} key="topico">
                Tela Cheia
              </nav>
            </div>
            <div className="posterBlock">
              <div
                className="posterDiv"
                style={{
                  width: `${scaleImg}%`,
                  marginLeft: `-${translateX}%`,
                  marginTop: `-${translateY}%`,
                }}
              >
                {renderer.map((item, index) => {
                  return (
                    <div
                      id="sector1"
                      style={{
                        top: `${item.top}%`,
                        left: `${item.left}%`,
                        width: `${item.width}%`,
                        height: `${item.height}%`,
                        transform: item.transform,
                      }}
                      className="sector"
                      onClick={() => zoom(index, item.type)}
                    />
                  );
                })}

                <img
                  className="posterImage"
                  src={posterItem.image}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div style={{background:"rgb(250,250,250)", width:'100%' }}><Youtube id={posterItem.video_id} /></div>
      )}

        <ChatRoom background="rgb(250,250,250)" id={posterItem.id} title="Comentários" />
</div>
      {active && (
        <Modal style={{ zIndex: 10000000 }}>
          <div style={{ background: '#fff' }}>
            <SendEmailToPoster
              hash={posterItem.hash}
              callback={() => setActive(false)}
            />
            <button
              className="closeButton"
              type="button"
              onClick={() => setActive(false)}
            >
              Fechar
            </button>
          </div>
        </Modal>
      )}
    </Container>
  );
};

export default PosterZoomComponent;
