import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../../../core/components/SearchComponent';

import lineModules from '../../../../core/components/lineModules'
import { Container } from './styles';
import { useSocket } from '../../../../../../hooks/Socket';
import { Button } from '../../../../core/components/Forms/Button/styles';

import review from './modules/Review';
import update from './modules/Update';

interface ICreateModule {
  lineData: Record<string, any>;

 
  
}

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const CientificSubmission: React.FC = () => {
  const endpoint = '/cientific-avaliators';
const {socket} = useSocket();
const title = 'Avaliadores';


const schema: Record<string, any> = {
  id: { ref: 'id', column: 'id', label: 'ID' },
  name: { ref: 'name', column: 'name', label: 'Nome' },
  email: { ref: 'email', column: 'email', label: 'Email' },
  document_number: { ref: 'document_number', column: 'document_number', label: 'CPF' },
  category: { ref: 'category', column: 'category', label: 'Categoria' },
  themes: { ref: 'themes', column: 'themes', show: false, label: 'Área de atuação' },
  lattes: { ref: 'lattes', column: 'lattes', show: false, label: 'Lates' },
  is_orientating: { ref: 'is_orientating', column: 'is_orientating', show: false, label: 'É orientador?' },
  papers_numbers: { ref: 'papers_numbers', column: 'papers_numbers',  label: 'Número de trabalhos' },
  orientation_time: { ref: 'orientation_time', column: 'orientation_time', show: false, label: 'Período para avaliação' },
  status: {
    ref: 'status',
    column: 'status_response',
    includeColumn: 'title',
    label: 'Status',
    filter: true,
  },
  createdAt: {
    ref: 'created_at',
    column: 'createdAt',
    label: 'Data de Criação',
    mask: 'date',
  },
};


const formSchema = {
  
  status: {
    alias: 'status',
    model: 'selectApi',
    name: 'status',
    label: 'Status',

    endpoint,
  },
 
  name: {
    model: 'input',
    name: 'name',
    label: 'Nome',
  },

  email: {
    model: 'input',
    name: 'email',
    label: 'Email',
  },
  document_number: {
    model: 'input',
    name: 'document_number',
    label: 'CPF',
  },
  themes: {
    model: 'jsonListMultiple',
    type: 'text',
    name: 'themes',
    label: 'Áreas de atuação',
    list: {
      category: { label: 'Categoria', name: 'category' },
      area: { label: 'Área', name: 'area' },
      subarea: { label: 'Subárea', name: 'subarea' },
    },
  },
  papers_numbers: {
    model: 'input',
    name: 'papers_numbers',
    label: 'Número máximo de trabalhos',
  },
  category: {
    model: 'input',
    name: 'category',
    label: 'Categoria',
  },
};

  const formSchemaUpdate = {
    ...formSchema,
   
  };

  

  const formValidation: Record<string, ValidationSchema> = {

  };


   function updateLessonModule({

    lineData,
  
   
  }: ICreateModule): JSX.Element {

    const clearLessonCache = () => {
      socket.emit('clearChatCache', {room_id : lineData.id});
    }

return <Button style={{padding: '5px'}} onClick={() => clearLessonCache()}>Limpar Cache</Button>

  }
 



  const config2: ISearchComponent = {
    idKey: endpoint,
    title: 'Avaliadores',
    endpoint: endpoint,
    schema: schema,
    autoStart: true,
    headModules: [
 
      () =>
      lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
      lineModules.updateUnique({
        lineData,
        title,
        formSchema,
        validationSchema: formValidation,
        endpoint,
     
      }),
      (lineData: Record<string, any>) =>
      review({
        lineData,
     
      }),
          
     
        (lineData: Record<string, any>) =>
          lineModules.delete({
            lineData,
            title,
            formSchema,
            validationSchema: formValidation,
            endpoint,
          }),
    ],
  };

  return (
    <Container>

      <SearchComponent {...config2} />
    </Container>
  );
};

export default CientificSubmission;
