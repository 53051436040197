import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap } from 'react-icons/fi';
import { Link } from 'react-router-dom';


import { MdError } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../../components/Forms/Input';
import Button from '../../../../components/Forms/Button';
import Loader from '../../../../components/Loader';
import MaskedInput from '../../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../../components/ResponseMessage';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { Error,Separator, Container, FixedResponse } from './styles';
import SelectSimple from '../../../../components/Forms/SelectSimple';
import Whatsapp from '../../../../components/WhatsAppCompartilhar';

import JsonListMultiple from '../../../../components/Forms/JsonListMultiple';

import api from '../../../../services/api';
import { authTitle, recaptchaKey, urlWebsite } from '../../../../config';
import getCep from '../../../../services/cep';
import { FaBuilding, FaGenderless, FaIdBadge, FaPhone } from 'react-icons/fa';
import CheckboxInput from '../../../../components/Forms/CheckboxInput';
import { useLanguage } from '../../../../hooks/Language';
import TextareaInput from '../../../../components/Forms/TextareaInput';
import { areas } from './areas';
import CheckboxIncluder from './components/CategoryChange';
import { modalidade } from './modalidade';
import { useToast } from '../../../../hooks/Toast';


interface IRecoverFormData {
  [key : string ] : any
}

interface ICaptcha {
  getValue(): string;
}

interface IOption {
  label : string;
  value: string;
  id : string;
}

const AvaliatorSubscription : React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(true);
  const formRef = useRef<FormHandles>(null);

  const [submissionData,setSubmissionData] = useState<Record<string,any>>({});
  const recaptchaRef = useRef(null);
  const {translate} = useLanguage();
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };
const {addToast} = useToast();
  const [haveAgency, setHaveAgency] = useState('Não');
  const [activeReview, setActiveReview] = useState(false);
  
  const [category, setCategory] = useState([]);
  const [area, setArea] = useState('');
  const [subarea, setSubarea] = useState('');
  const [countryISO, setCountryISO] = useState('BR');

  const [categoryOptions, setCategoryOptions] = useState<Array<IOption>>([]);
  const [areaOptions, setAreaOptions] = useState<Array<IOption>>([]);
  const [subareaOptions, setSubareaOptions] = useState<Array<IOption>>([]);

  const [initialData,setInitialData] = useState({});







  useEffect(() => {

    const areaOptions : Array<IOption> = [];

    areas.map( a => {
      areaOptions.push( { label: a.title, value : a.title, id: a.title });
    })

    setCategoryOptions(areaOptions);
   
  },[])


  const box = (dataInfo,key = '') => {
    if(!dataInfo){
        return <></>
    }
    return <>
    {dataInfo.map( d => <aside style={{ background: '#eee',  padding: '5px', margin: '5px', color: '#333', width:'auto'}}>{key === 'self' ? d || '' : d?.[key] || ''}</aside>)}
    </>
}
  

  const checkCategoria = (category,area) => {

    const categoryList = area.finIndex( a => a.title === category);

    if(!category){
      addToast({title: 'Campo categoria de submissão é obrigatório'});
      setLoading(false);
      return;
    }

  }
  
  const handleNewLogin = async (data: IRecoverFormData) => {

    data.themes= category;
    data.category = data.category_id;


    setLoading(true);


     
      

      
      data.papers_numbers = data.papers_numbers?.[0] ? data.papers_numbers?.[0] : '';
      data.turno_apresentacao = data.turno_apresentacao?.[0] ? data.turno_apresentacao?.[0] : '';
 
    try {
      formRef.current?.setErrors({});

      



      const shape : Record<string,any> = {
        name : Yup.string().required('O campo Nome do autor é obrigatório'),
        document_number: Yup.string().required('O campo CPF é obrigatório'),
        email : Yup.string().required('O campo Email do autor é obrigatório'),
        password : Yup.string().required('O campo senha é obrigatório'),
        papers_numbers: Yup.string().required('Especifique o número de trabalhos que deseja avaliar'),
        themes : Yup.string().required('O campo áreas de atuação é obrigatório'),
        category: Yup.string().required('O campo categoria é obrigatório'),
        is_orientating: Yup.string().required('Informe se você é orientador de alunos dos programas de IC, ITI, PID, Extensão'),

       // orientation_time: Yup.string().required('O campo Turno de avaliacao é obrigatório'),
      };

      if(categoryValue === 'Doutorando de Programas de Pós-Graduação de outras Instituições de Ensino Superior'){
        shape.lattes = Yup.string().required('O Currículo Lattes é obrigatório');
      }

 

     

      const schema = Yup.object().shape(shape);

      await schema.validate(data, { abortEarly: false });

  
      
      if(!activeReview){
        setSubmissionData(data);
        setActiveReview(true);
        setLoading(false);
        return ;
      }

      
        

      const response =  await api.post(`/signup-avaliator`, {
        ...data
 
    
      });

      setSuccess(true);
      setLoading(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (formRef && formRef.current && formRef.current.setErrors) {
          formRef.current.setErrors(errors);
        }
        setLoading(false);

        const errorsString = Object.keys(errors).reduce(
          (prev: Array<string>, key: string): Array<string> => {
            prev = [...prev, errors[key]];
            return prev;
          },
          [],
        );

        setErrorMessage(errorsString);

        return;
      }

      console.log(err);

      setErrorMessage([
        err?.response?.data?.message ||
          err?.response?.data?.error ||
          'Erro ao gerar seu cadastro',
      ]);
    }
    setLoading(false);
  };

  const [categoryValue,setCategoryValue] = useState('');
  

  return (
    <>
      {loading === true && (
        <Loader message="Preparando seu cadastro..." position="fixed" />
      )}

      <aside>
        {success ? (<div style={{width:'100%', display:'flex', justifyContent: 'center'}}>
          <ResponseMessage
            active
            type="success"
            title="Sucesso"
            description={
              <p>
                Cadastro realizado com sucesso!
                <br />
              
           <aside style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
              <Link to="/login-avaliador"><button className="defaultButton">Acessar área do avaliador</button></Link>
              </aside>
              </p>
            }
          /></div>
        ) : (<Container>
          <Form ref={formRef} initialData={initialData} onSubmit={handleNewLogin} style={{maxWidth:'800px'}}>
            <h1>Cadastro de avaliadores</h1>
            <p>Cadastro para avaliador dos resumos submetidos ao Congresso UFCSPA, cada resumo terá até 2000 caracteres.</p>
            <Separator>
              <label>1.1 - Dados do Avaliador</label>
            <Input  name="name" placeholder="Nome Completo" />
            <MaskedInput mask="999.999.999-99"  name="document_number" placeholder="CPF" />
            </Separator>

            <Separator>
              <label>1.2 - Dados do Acesso</label>
              <Input  name="email" placeholder="Email" />
              <Input type="password"  name="password" placeholder="Cadastre sua senha de acesso" />
            </Separator>

            <Separator>
              <label>1.3 - Informações adicionais</label>
              <p style={{color: '#fff'}}>É orientador de alunos dos programas de IC, ITI, PID, Extensão?</p>
              <SelectSimple name="is_orientating" label="" options={[
              { value: 'Sim', label: 'Sim'},
              { value: 'Não', label: 'Não'},
            ]} />
            
            </Separator>

            <Separator>
              <label>1.4 - Categoria</label>
              <SelectSimple name="category_id" label="" setCallback={(value) => { setCategoryValue(value)}} options={[
              { value: 'Professor da UFCSPA', label: 'Professor da UFCSPA'},
              { value: 'Doutorando ou pós-doc dos Programas de Pós - Graduação da UFCSPA', label: 'Doutorando ou pós-doc dos Programas de Pós - Graduação da UFCSPA'},
              { value: 'Professor doutor de outras Instituições de Ensino Superior', label: 'Professor doutor de outras Instituições de Ensino Superior'},
              { value: 'Doutorando de Programas de Pós-Graduação de outras Instituições de Ensino Superior', label: 'Doutorando de Programas de Pós-Graduação de outras Instituições de Ensino Superior'},
      ]} />
            {categoryValue === 'Doutorando de Programas de Pós-Graduação de outras Instituições de Ensino Superior'  && <Input  name="lattes" placeholder="Link do Currículo Lattes" />}
            </Separator>
            
             






            <Separator>
              <label>2 - Área de atuação</label>

              <p style={{color: '#fff'}}>Área de atuação dentro das categorias de ensino, pesquisa e extensão (indicar 2 ou mais subáreas quando houver). <br/> O professor que atua em mais de uma categoria deve indicar as subáreas em todas as categorias que atua.</p>
         
              <CheckboxIncluder category={category} area={area} subarea={subarea} setSubarea={(value) => setSubarea(value)} setArea={(value) => setArea(value)} setCategory={(value) => setCategory(value)} name="category" options={areas} placeholder="Categorias" />

            </Separator>
            

            <Separator>
              <label>3 - Número de resumos para avaliação</label>
              <CheckboxInput unique name="papers_numbers" options={
                [
                  { value : '5 a 8', id:'5 a 8', label: '5 a 8'},
                  { value : '9 a 11', id:'9 a 11', label: '9 a 11'},
                  { value : '12 a 14', id:'12 a 14', label: '12 a 14'}
                ]
              } placeholder="" />

            </Separator>

  {/*
            <Separator>
              <label>4 - Turno disponível para banca de avaliação (indicar 2 ou mais turnos)</label>
              <p style={{color: '#fff'}}>Apresentação dos trabalhos da Mostra de trabalhos de Ensino, Pesquisa e Extensão será nos dias 29 e 30/11/21 nos três turnos.</p>
              (	) 


            <CheckboxInput  name="orientation_time" options={
                [
                  { value : 'segunda-feira 29/11/21 – manhã', id:'segunda-feira 29/11/21 – manhã', label: 'segunda-feira 29/11/21 – manhã'},
                  { value : 'segunda-feira 29/11/21 – tarde', id:'segunda-feira 29/11/21 – tarde', label: 'segunda-feira 29/11/21 – tarde'},
                  { value : 'segunda-feira 29/11/21 – noite', id:'segunda-feira 29/11/21 – noite', label: 'segunda-feira 29/11/21 – noite'},
                  { value : 'terça-feira 30/11/21 – manhã', id:'terça-feira 30/11/21 – manhã', label: 'terça-feira 30/11/21 – manhã'},
                  { value : 'terça-feira 30/11/21 – tarde', id:'terça-feira 30/11/21 – tarde', label: 'terça-feira 30/11/21 – tarde'},
                  { value : 'terça-feira 30/11/21 – noite', id:'terça-feira 30/11/21 – noite', label: 'terça-feira 30/11/21 – noite'}
                ]
              } placeholder="" />
              
            </Separator> */}



            {errorMessage && (
              <Error>
                {errorMessage.map((item, index) => (
                  <div key={`error${index}`}>
                    <MdError />
                    {item}
                  </div>
                ))}
              </Error>
            )}

<aside style={{display:'flex', justifyContent:'center'}}>
            <Button style={{ width: '100%' }} type="submit">
              Enviar
            </Button>
</aside>

<FixedResponse style={{ display: activeReview ? 'flex' : 'none'}}>
        <div>
        <h1>Revisão de dados</h1>

        <h2>1 - Dados do Avaliador</h2>
        <strong>Nome completo</strong><p>{submissionData?.name}</p>
        <strong>CPF</strong><p>{submissionData?.document_number}</p>
       
        
        <h2>1.2 - Dados de acesso</h2>
        <strong>Email</strong><p>{submissionData?.email}</p>

        <h2>1.3 - Informações adicionais</h2>
        <strong>É orientador de alunos dos programas de IC, ITI, PID, Extensão?</strong><p>{submissionData?.is_orientating}</p>
        <strong>Link do Currículo Lattes</strong><p>{submissionData?.lattes}</p>

        <h2>1.4 - Categoria</h2>
        <strong>Categoria</strong><p>{submissionData?.category}</p>
        { submissionData?.category === 'Doutorando de Programas de Pós-Graduação de outras Instituições de Ensino Superior' && <><strong>Link do Currículo Lattes</strong><p>{submissionData?.lattes}</p></>}



        <h2>2 - Área de atuação</h2>
        {submissionData?.themes && submissionData?.themes.map( d => <aside style={{ background: '#eee',  padding: '5px', margin: '5px', color: '#333', width:'auto'}}>{ d?.subarea ? `${d.category} > ${ d.area} > ${d.subarea}` : `${d.category} > ${ d.area}`}</aside>)}

        <h2>3 - Número de resumos para avaliação</h2>

        <strong>Quantidade</strong><p>{submissionData?.paper_numbers}</p>
        
        
      {/*  <h2>4 - Turno disponível para banca de avaliação (indicar 2 ou mais turnos)</h2>

      <strong>Datas</strong><p>{box(submissionData?.orientation_time, 'self')}</p> */}

   

        <button className="defaultButtonReverse" type="button" onClick={() => setActiveReview(false)}>Editar</button>
        <button className="defaultButton" type="submit">Enviar</button>
        </div>

        </FixedResponse>
          </Form>
          </Container>
        )}
      </aside>

     
    </>
  );
};

export default AvaliatorSubscription;

