import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../../../core/components/SearchComponent';

import lineModules from '../../../../core/components/lineModules'
import { Container } from './styles';
import { useSocket } from '../../../../../../hooks/Socket';
import { Button } from '../../../../core/components/Forms/Button/styles';

import review from '../../dashboardPages/CientificSubmission/modules/Review';
import avaliarTrabalhos from '../../modules/AvaliarTrabalho';
import correcaoOral from '../../modules/CorrecaoOral';
import destaque from '../../modules/Destaque';
import visualizacaoOral from '../../modules/VisualizacaoOral';
import update from '../../dashboardPages/CientificSubmission/modules/Update';

interface ICreateModule {
  lineData: Record<string, any>;

 
  
}

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const MyPapersOral: React.FC = () => {
  const endpoint = '/my-avaliations-oral';
const {socket} = useSocket();
const title = 'Minhas avaliações';


const schema: Record<string, any> = {
  id: { ref: 'id', column: 'id', label: 'ID' },
  reference: { ref: 'reference', column: 'reference', label: 'Etapa' },
  paper_id: {
    ref: 'paper_id',
    column: 'paper_id_response',
    includeColumn: 'presentation_room',
    label: 'Sessão',

  },
  title: { ref: 'title', column: 'title', label: 'Título' },
  category: { ref: 'category', column: 'category', label: 'Categoria'},
  area: { ref: 'area', column: 'area', label: 'Área'},
  subarea: { ref: 'subarea', column: 'subarea', label: 'Subárea'},
  highlight: {
    ref: 'highlight',
    column: 'highlight_response',
    includeColumn: 'title',
    label: 'É Destaque?',
    filter: true,
  },
  conflict: {
    ref: 'conflict',
    column: 'conflict_response',
    includeColumn: 'title',
    label: 'Há conflito de interesse?',
    filter: true,
  },
  status_id: {
    ref: 'status_id',
    column: 'status_id_response',
    includeColumn: 'title',
    label: 'Já foi avaliado?',
    filter: true,
  },
  avaliator_id: {
    ref: 'avaliator_id',
    column: 'avaliator_id_response',
    includeColumn: 'name',
    label: 'Avaliador',

  },

};

/*
 (lineData: Record<string, any>) =>
          update({
            lineData,
         
          }),

          */

  const config2: ISearchComponent = {
    idKey: endpoint,
    title: 'Avaliação de apresentação oral',
    endpoint: endpoint,
    schema: schema,
    autoStart: true,
    headModules: [],
    bodyModules: [
          (lineData: Record<string, any>) =>
          correcaoOral({
            lineData,
         
          }),
          (lineData: Record<string, any>) =>
          destaque({
            lineData,
         
          }),
         
          
      
    ],
  };

  return (
    <Container>

      <SearchComponent {...config2} />
    </Container>
  );
};

export default MyPapersOral;
