export const areas = [
 
    { 
        title: 'Ensino',
        subname: 'area',
        subcategory : 'Áreas',

        description: 'Trabalhos ligados a projetos do Programa de Iniciação à Docência (PID-UFCSPA) que estimulam o desenvolvimento de metodologias inovadoras que contribuam com a melhoria do ensino na graduação através do estabelecimento de novas práticas e experiências pedagógicas.',
        lists : [
            { title : 'Programa de Iniciação à Docência (PID-UFCSPA) - exclusivo para bolsistas',
              lists : []
            },
   
        ]
     },

     { 
        title: 'Pesquisa',
        subname: 'area',
        subcategory : 'Áreas',
        description: `Trabalhos referentes a projetos que buscam respostas, novos conhecimentos e produtos
        mediante a utilização de procedimentos e métodos embasados em rigor científico,
        tecnológico, literário e/ou artístico, desenvolvidos no âmbito dos programas de Iniciação
        Científica (PIBIC-CNPq, PIBIC-Af-CNPq, PIC-UFCSPA, PROBIC-Fapergs, PROBITI-Fapergs)
        vigência 2020-2021.`,
        lists : [
            { title : 'Ciências Exatas e da Terra',
            subname: 'subarea',
            subcategory : 'Subáreas',
              lists : [
                { title : 'Ciência da Computação'},
                { title : 'Engenharia Biomédica'},
                { title : 'Física'},
                { title : 'Matemática'},
                { title : 'Química'},
                { title : 'Teleinformática'},
                { title : 'Outra'}
              ]
            },
            { title : 'Ciências Biológicas e Ambientais',
            subname: 'subarea',
            subcategory : 'Subáreas',
            lists : [
                { title : 'Biofísica'},
                { title : 'Bioquímica'},
                { title : 'Botânica'},
                { title : 'Ecologia'},
                { title : 'Farmacologia'},
                { title : 'Fisiologia'},
                { title : 'Genética'},
                { title : 'Imunologia'},
                { title : 'Microbiologia'},
                { title : 'Morfologia'},
                { title : 'Parasitologia'},
                { title : 'Toxicologia'},
                { title : 'Outra'},
            ]
          },
          { title : 'Ciências da Saúde e Agrárias',
          subname: 'subarea',
          subcategory : 'Subáreas',
            lists : [
                { title : 'Ciência e Tecnologia de Alimentos'},
                { title : 'Educação Física'},
                { title : 'Enfermagem'},
                { title : 'Farmácia'},
                { title : 'Fisioterapia e Terapia Ocupacional'},
                { title : 'Fonoaudiologia'},
                { title : 'Medicina'},
                { title : 'Nutrição'},
                { title : 'Odontologia'},
                { title : 'Saúde Coletiva'},
                { title : 'Outra'}
            ]
        },
        { title : 'Ciências Sociais Aplicadas',
        subname: 'subarea',
        subcategory : 'Subáreas',
          lists : [
           { title :'Administração'},
           { title :'Ciência da Informação'},
           { title :'Divulgação Científica'},
           { title :'Outra'}
          ]
        },
        { title : 'Ciências Humanas e Bioética',
        subname: 'subarea',
        subcategory : 'Subáreas',
        lists : [
           { title :'Bioética'},
           { title :'Educação'},
           { title :'Psicologia'},
           { title :'Outra'}
        ]
        },
        { title : 'Linguística, Letras e Artes',
        lists : [ ]
        },
        
        
        ]
     },

     { 
        title: 'Extensão',
        subname: 'area',
        subcategory : 'Áreas',
        description: 'Trabalhos oriundos de programas e/ou projetos de Extensão (PROBEXT), vigências 2020 e 2021, que explicitem a integração da universidade com a sociedade, formação cidadã e atividades que valorizem a interprofissionalidade.',
        lists : [
            { title : 'Comunicação',
              lists : []
            },
            { title : 'Cultura',
            lists : []
            },
            { title : 'Direitos Humanos e Justiça',
            lists : []
            },
            { title : 'Educação',
            lists : []
            },
            { title : 'Meio Ambiente',
            lists : []
            },
            { title : 'Saúde',
            lists : []
            },
            { title : 'Tecnologia e Produção',
            lists : []
            },
            { title : 'Trabalho',
            lists : []
            }
        ]
     }


]