import React from 'react';
import { FaArrowCircleRight } from 'react-icons/fa';
import { useModal } from '../../../../../../hooks/Modal';
import { useSearch } from '../../../../../../hooks/Search';
import { useToast } from '../../../../../../hooks/Toast';
import api from '../../../../../../services/api';
import {Container} from './style';
interface IProps {
    data : Record<string,any>
}

const ReviewAvaliadores : React.FC<IProps> = ({data}) => {
const {addToast} = useToast();
const {removeModal} = useModal();
const {reloadSearchAll} = useSearch();
const changeStatus = async (id,status) => {
    try{
        const response = await api.put(`/cientific-avaliators/${id}/status`,{ status });

        if(response){
          
            await reloadSearchAll();
            addToast({title : 'Atualizado com sucesso', type: 'success'});
          return  removeModal('avaliator');
        }
    return;
    }
    catch(err){
        console.log(err);
      return  addToast({title : err?.response?.data?.message || err?.response?.data?.error || 'Erro ao atualizar', type: 'success'});
    }
}

    const box = (dataInfo,key = '') => {
        if(!dataInfo){
            return <></>
        }
        return <>
        {dataInfo.map( d => <aside style={{ background: '#eee',  padding: '5px', margin: '5px', color: '#333', width:'auto'}}>{key === 'self' ? d || '' : d?.[key] || ''}</aside>)}
        </>
    }

    return <Container>
     <h1>Revisão de dados</h1>

<h2>1 - Dados do Avaliador</h2>
<strong>Nome completo</strong><p>{data?.name}</p>
<strong>CPF</strong><p>{data?.document_number}</p>


<h2>1.2 - Dados de acesso</h2>
<strong>Email</strong><p>{data?.email}</p>

<h2>1.3 - Informações adicionais</h2>
<strong>É orientador de alunos dos programas de IC, ITI, PID, Extensão?</strong><p>{data?.is_orientating}</p>
<strong>Link do Currículo Lattes</strong><p>{data?.lattes}</p>

<h2>1.4 - Categoria</h2>
<strong>Categoria</strong><p>{data?.category}</p>
{ data?.category === 'Doutorando de Programas de Pós-Graduação de outras Instituições de Ensino Superior' && <><strong>Link do Currículo Lattes</strong><p>{data?.lattes}</p></>}



<h2>2 - Área de atuação</h2>
{data?.themes && data?.themes.map( d => <aside style={{ background: '#eee',  padding: '5px', margin: '5px', color: '#333', width:'auto'}}>{ d?.subarea ? `${d.category} > ${ d.area} > ${d.subarea}` : `${d.category} > ${ d.area}`}</aside>)}

<h2>3 - Número de resumos para avaliação</h2>

<strong>Quantidade</strong><p>{data?.papers_numbers}</p>


<h2>4 - Turno disponível para banca de avaliação (indicar 2 ou mais turnos)</h2>

<strong>Datas</strong><p>{box(data?.orientation_time, 'self')}</p>

<br/>
<button onClick={() => changeStatus(data.id,2)} style={{width: '150px', margin: '10px auto'}} className="defaultButton">Aprovar</button> <button style={{width: '150px'}}  className="defaultButtonRed" onClick={() => changeStatus(data.id,3)}>Rejeitar</button>
        </Container>
}

export default ReviewAvaliadores;