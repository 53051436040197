import React, { useEffect, useState } from 'react';
import Button from '../../../../../../components/Forms/Button';

import {Container} from './styles';
import AvaliatePapers from '../MyPapers';
import AvaliatePapersOral from '../MyPapersOral';
import AvaliatePapersFinished from '../AvaliatePapersFinished';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { authTitle, supportNumber } from '../../../../../../config';
import Whatsapp from '../../../../../../components/Whatsapp';
import { useAuth } from '../../../../../../hooks/Auth';

interface IParams {
  tab : string;
}

const CientificHome: React.FC = () => {
const [type, setType] = useState('avaliacoes_oral');
const {tab} = useParams<IParams>();
const {user} = useAuth();
const [validTab,setValidTab] = useState({
  home : '',
  avaliar : 'avaliar',
  trabalhos_avaliados : 'trabalhos_avaliados'
});

useEffect(() => {
  if(!user ){
    window.location.href = '/login-avaliador';
  }

  if(user.profile !== 10 && user.profile !== 1){
    window.location.href = '/login-avaliador';
  }
  return;
},[])

useEffect(() => {
  if(tab && validTab[tab]){
    setType(validTab[tab]);
  }
},[tab])

if(type === 'avaliacoes') { return <AvaliatePapers/>}
if(type === 'avaliacoes_oral') { return <AvaliatePapersOral/>}
if(type === 'avaliar-trabalhos'){ return <AvaliatePapersFinished/>}

  return (
    <>

      <Container >
        <h3>
          ÁREA DO AVALIADOR
        </h3>
<div style={{display:'flex', alignItems: 'center', flexWrap:'wrap', justifyContent:'center', padding: '10px'}}>
      
{ /*
        <a target="_BLANK" href="/arquivos_apoio/orientacao_avaliacao.docx"><button className="defaultButton2" style={{background: 'orange'}}>Orientações para avaliação</button></a>
<Button onClick={() => {setType('avaliacoes')}}>Minhas Avaliações </Button>*/}
<Button onClick={() => {setType('avaliacoes_oral')}}>Avaliações orais</Button>
        <Whatsapp number={supportNumber} text={`${authTitle}: Sou um avaliador de trabalhos`} message="Suporte Técnico"/>
        
        </div>
       
   <div style={{ margin: '20px auto', width: '100%', maxWidth: '400px', border: '2px solid #fff', padding: '20px', borderRadius: '20px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
  <strong>Prezado (a) Avaliador (a),</strong> 
<br/><br/>Sua colaboração na avaliação online de resumos submetidos ao Congresso UFCSPA  e/ou nas sessões de apresentação oral é muito bem-vinda. 
<br/><br/>A comissão organizadora do Congresso UFCSPA autorizará sua função como avaliador(a) e encaminhará alguns resumos para seu parecer, de acordo com a área escolhida, à medida que for recebendo os trabalhos. Após o encerramento do Congresso UFCSPA, será disponibilizado o certificado pela sua atuação como avaliador(a). No decorrer do cadastro, também solicitamos seus turnos de disponibilidade para atuar como avaliador(a) nas sessões de apresentação oral durante a Mostra de Trabalhos de Ensino, Pesquisa e Extensão do Congresso UFCSPA, que ocorre de forma online nos dias 29 e 30 de novembro de 2021. A confirmação das datas e turnos de sua participação nas sessões de apresentação oral será realizada próxima ao evento.
<br/><br/>Acima deste texto, você encontrará um botão com as orientações sobre a avaliação dos resumos.
<br/><br/>Dúvidas e informações, enviem para o e-mail: congressoufcspa@ufcspa.edu.br
<br/><br/>Seja bem-vindo(a)!
<br/><br/>Obrigado por colaborar conosco!
   </div>

      </Container>
    </>
  );
};
export default CientificHome;
