import styled from 'styled-components';
import themeDefault from '../../../styles/ThemeComponents';
import {ContainerRef} from '../styles';


export const Container = styled(ContainerRef)`
flex-direction: column;
padding: 20px;
align-items: flex-end;
justify-content: flex-start;
  textarea {
    width: 100%;
    min-height: 200px;
    border: 0;
    border: 1px solid #333;
    border-radius: 5px;
    color: ${themeDefault.input.color};
    padding: 16px;
    background: transparent;

    &::placeholder {
      color: ${themeDefault.input.placeholder};
    }
  }

`;
