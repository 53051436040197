import styled from 'styled-components';
import theme from '../../../../../../../../styles/ThemeComponents';

export const Container = styled.aside`
  display: flex;
  align-items: flex-start;
  justify-content: center ;
  flex-direction: column;
  flex-wrap: wrap;

  background: none;
  color: ${theme.input.color};
  border-radius: 0;
  border-bottom: ${theme.input.border};
  padding: 10px 10px;
  padding-right: 0px;
  position: relative;
background: none !important;
border: 0px !important;
width: 100%;
color: #fff;

p{
  margin: 10px 0px ;
}
  > div {
    width: 80%;
    max-width: 350px;
    font-size: 14px;
    padding: 16px;
    text-align:left;
>label{
  width: 100%;
  text-align: left;
}
  }

  > label {
 
    margin-top: 10px;
    border-radius: 0px;

    margin-right: 16px;
    display: flex;
    text-align:left;
    align-items: center;
    font-size: 14px;
    width: 100%;
  text-align: left;
    > input {
      width: 25px;
      height: 25px;
      margin: 5px;
    
    }
    > p {
      font-size: 14px;
      
    }
  }
`;
