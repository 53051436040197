import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';

import { useField, FormHandles } from '@unform/core';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';

import  {CKEditor, CKEditorContext} from '@ckeditor/ckeditor5-react';
import Context from '@ckeditor/ckeditor5-core/src/context';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



import { Container } from './styles';
import { Error } from '../styles';
import stringConverter from '../../../../../../utils/stringConverter';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
}

const Input: React.FC<InputProps> = ({
  name,
  icon: Icon,
  formRef,
  setCallback = () => {},
  ...rest
}) => {
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocus, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(!!defaultValue);
const [contador,setContador] = useState(0);
  const selectRef = useRef(null);

  const [currentValue, setCurrentValue] = useState( defaultValue || '');

  function convertToPlain(html){

    // Create a new div element
    var tempDivElement = document.createElement("div");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element 
    return tempDivElement.textContent || tempDivElement.innerText || "";
}

  useEffect(() => {
    const length = convertToPlain(currentValue).length;
    setContador(length);

    const newValue = stringConverter(currentValue,'decode');
    if(currentValue !== newValue){
      setCurrentValue(newValue);
    }

  },[currentValue])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: undefined,

      getValue: (ref: any) => {
        return ref.props.data;
      },
      setValue: (ref: any, value) => {
        ref.props.data = value;
      },
    });
  }, [fieldName, registerField, currentValue]);

  const handleInputBlur = useCallback(() => {
    setIsFocus(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  useEffect(() => {
    registerField({ name: fieldName, ref: inputRef.current, path: 'value' });
  }, [fieldName, registerField]);

  return (<>
    <Container
      {...rest}
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocus}
    >
      <span>{rest.placeholder}</span>
      {Icon && <Icon />}
     
      <CKEditor style={{width: '100%'}}
        ref={selectRef}
        editor={ClassicEditor}
        data={currentValue}
        config={{toolbar: [ 'bold', 'italic','alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify' ],
      }}
        
        onInit={editor => {}}
        onChange={(event, editor) => {
          const data = editor.getData();
          setCurrentValue(data);
        }}
        onBlur={(event, editor) => {
          console.log('Blur.', editor);
        }}
        onFocus={(event, editor) => {
          console.log('Focus.', editor);
        }}
      />
     

      {error && (
        <Error title={error}>
          <FiAlertCircle />
        </Error>
      )}
    </Container>
     <p style={{padding: '10px', width: '100%', textAlign: 'right'}}>{contador} caractere(s)</p></>
  );
};

export default Input;
