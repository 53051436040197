

const stringConverter = (string,type) => {

    let newString = string;

    const list = [
        ['α','&alpha'],
        ['β','&beta'],
        ['γ',	'&gamma'],
        ['Γ','&Gamma'],
        ['δ','&delta'],
        ['Δ','&Delta'],
        ['ε','&epsilon'],
        ['Ε','&Epsilon'],
        ['ζ','&zeta'],
        ['Ζ','&Zeta'],
        ['η','&eta'],
        ['Η','&Eta'],
        ['θ','&theta'],
        ['Θ','&Theta'],
        ['ι','&iota'],
        ['Ι','&Iota'],
        ['κ','&kappa'],
        ['Κ','&Kappa'],
        ['λ','&lambda'],
        ['Λ','&Lambda'],
        ['μ','&mu'],
        ['Μ','&Mu'],
        ['ν','&nu'],
        ['Ν','&Nu'],
        ['ξ','&xi'],
        ['Ξ','&Xi'],
        ['ο','&omicron'],
        ['Ο','&Omicron'],
        ['π','&pi'],
        ['Π','&Pi'],
        ['ρ','&rho'],
        ['Ρ','&Rho'],
        ['σ','&sigma'],
        ['Σ','&Sigma'],
        ['ς','&sigmaf'],
        ['ϖ','&piv'],
        ['τ','&tau'],
        ['Τ','&Tau'],
        ['υ','&upsilon'],
        ['Υ','&Upsilon'],
        ['φ','&phi'],
        ['Φ','&Phi'],
        ['χ','&chi'],
        ['Χ','&Chi'],
        ['ψ','&psi'],
        ['Ψ','&Psi'],
        ['ω','&omega'],
        ['Ω','&Omega'],
        ['ϑ','&thetasym'],
        ['ϒ','&upsih'],
        ['≅','&#8773;'],
        ['≈','&#8776;'],
        ['≠','&#8800;'],
        ['≡','&#8801;'],
        ['≢','&nequiv;'],
        ['≤','&le;'],
        ['≥','&ge;'],
        ['≦','&lE;'],
        ['≧','&gE;'],
        ['≨','&lnE;'],
        ['≩','&gnE;']
    ];

   

   
if(newString){
            list.map( l => {
                newString = newString.split(l[ type==="encode" ? 0 : 1]).join(l[type==="encode" ? 1 : 0])

            })
        }
        


        return newString;

}

export default stringConverter;