import styled from 'styled-components';

export const Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;

  > div {
    > svg {
      margin-right: 5px;
    }
    padding: 5px 10px;
    width: 100%;
    background: rgb(150, 0, 0);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: 14px;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  position: relative;

  form{
    width: 100%;
    max-width: 1000px !important;
    margin: 10px auto !important;

 
  }

  div{
    width: 100%;

  }

  .react-select__control{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    >div {
      width: 100%;
    }

    >div.react-select__indicators{
      width: 70px;
    }
  }
  `;

export const Separator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  position: relative;

  padding: 20px 20px;
  background: none !important;
  margin-top: 30px;
  >label {
    position: absolute;
    top: -12.5px;
    left: 20px;
    width: auto;
    background: #fff;
    color: #333;
    padding: 5px;
    border-radius: 5px;
  }

  form{
    width: 100%;
    max-width: 800px !important;
    margin: 10px auto !important;

  
  }

  div{
    width: 100%;

  }
`;



export const FixedResponse = styled.div`

position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.3) !important;
  z-index: 100000000;
  
>div{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  background: #fff;
  padding: 40px 40px;
  max-height: 80vh;

  max-width: 800px;
  overflow-y: auto;


  >button.close{}

  >h1{
    width: 100%;
    font-size: 24px;
    color: #333;
    margin: 10px auto;
  }

  >h2{
    width: 100%;
    margin-top: 20px;
    font-size: 18px;
    color: rgb(0,100,150);
 
  }

  >strong{
    width: 100%;
    font-size: 16px;
    color: #333;
    margin-top: 10px;
  }

  >p{
    width: 100%;
    font-size: 16px;
    color: #333;
  }

  >button{
    margin: 10px auto;
  }

}


`;