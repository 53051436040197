import React from 'react';
import { FaEye, FaIntercom, FaRadiationAlt } from 'react-icons/fa';
import CopyToClipboard from '../../../../../core/components/CopyToClipboard';
import { urlWebsite } from '../../../../../../../config';
import { useSocket } from '../../../../../../../hooks/Socket';
import { useModal } from '../../../../../../../hooks/Modal';
import Review from '../../../modules/Review';

interface ICreateModule {
    lineData: Record<string, any>;
  }
  
  export default function ReviewModule({
    lineData,
  }: ICreateModule): JSX.Element {

    const {addModal } = useModal();

    const openReview = (line) => {

       addModal({ title: 'Trabalho submetido', content: <Review data={lineData}/>  });
    }


  const moduleCreate = (): JSX.Element => { 

    return <div onClick={() => openReview(lineData)} style={{cursor: 'pointer',width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', border: '1px solid #333', margin: '5px'}}>
      <FaEye className="hover" style={{cursor:'pointer'}} size={20} /></div>;
  }
     
  

  return moduleCreate();
}
