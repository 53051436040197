import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;

  z-index: 1;
  > aside {
    display: flex;
    flex-direction: row;

    > button.active {
      box-shadow: 0px 0px 8px rgba(0,0,0,0.5);
    }
    > button {
      padding: 10px;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0px 0px 8px rgba(0,0,0,0.3);
      margin: 20px;
      max-width: 200px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 0;
      > img {
        width: 100%;
        max-width: 200px;
      }

      > span {
        font-weight: bold;
      }

      > input {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        opacity: 0.01;
      }
    }
  }

  @media (max-width: 450px) {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
  }
`;
