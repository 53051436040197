import React, { Dispatch, useRef, useCallback, useState, useEffect } from 'react';

import { Form } from '@unform/web';
import {
  FiMail,
  FiLock,
  FiUser,
  FiArrowRight,
  FiArrowLeft,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useAuth } from '../../hooks/Auth';
import Input from '../Forms/Input';
import MaskedInput from '../Forms/MaskedInput';
import { useToast } from '../../hooks/Toast';
import { AnimatedDiv } from './styles';
import { LoginContent } from './styles';
import getValidationErrors from '../../utils/getValidationErrors';

import Button from '../Button';

import api from '../../services/api';
import { FaArrowLeft } from 'react-icons/fa';
import { useModal } from '../../hooks/Modal';





interface LoginProps {

  company: string; 
  phone: string; 
  position: string;
  
  document_number:string;
  birth_date: string;
}

interface IUpdate {
  phone : string;
  document_number : string;
  company: string;
  position: string;
  birth_date : Date;
}

const LoginContainer: React.FC = () => {
  const { user,token } = useAuth();
  const { addToast } = useToast();
  const {  removeModal} = useModal();
  const formRef = useRef<FormHandles>(null);

  const [defaultValue, setDefaultValue] = useState<IUpdate>({} as IUpdate);
  const [reloadKey, setReloadKey] = useState('formUpdate');
   

useEffect( () => {

  const load = async() => {
    if(!user || !token){
      return false;
    }
  const response = await api.get(`student-info/${user.id}`);

  if(response){



    setDefaultValue({...response.data, birth_date : response.data.birth_date.toString()
      .replace(/[^0-9]/g, '')});
    setReloadKey(`d${new Date().getMilliseconds()}`);
  }

  }
load();
},[])

if(!user || !token){
  return <></>;
}


  const handleNewLogin = 
    async (data: LoginProps) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
  
            company: Yup.string().required('Campo Empresa é obrigatório'),
            position: Yup.string().required('Preencha seu Cargo'),
            phone: Yup.string()
            .min(10, 'Telefone deve conter ao menos 10 caracteres')
            .max(11, 'Telefone deve conter no máximo 11 caracteres')
            .required('Telefone obrigatório'),
         
          document_number: Yup.string()
            .min(11, 'Preencha a CPF / CNPJ')
            .required('Número do documento obrigatório'),

          birth_date: Yup.string()
            .min(10, 'min 10 Preecha a Data de Nascimento')
            .max(10, 'max 10 Preecha a Data de Nascimento')
            .required('Data de Nascimento obrigatória'),
        });
        data.phone = data.phone.toString().replace(/[^0-9]/g, '');
        data.birth_date.toString()
        .replace(/[^0-9/]/g, '');
        data.document_number.toString()
        .replace(/[^0-9]/g, '');
        await schema.validate(data, { abortEarly: false });
  
        const { company, phone, position, 
          document_number,
        
          birth_date } = data;

        const newUser = await api
          .post(`/updateUser/${user.id}`, {  company, phone, position,
            document_number : document_number.toString()
            .replace(/[^0-9]/g, ''),
          
            birth_date })
          .catch(error => {

            if (error instanceof Yup.ValidationError) {
              const errors = getValidationErrors(error);
              formRef.current?.setErrors(errors);
            }

            addToast({
              type: 'error',
              title: error.response.data.message,
            });
            return false;
          });

          addToast({
            type: 'success',
            title: 'Atualizado com sucesso!',
            description: '',
          });

          removeModal('userUpdateForm');
     
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          console.log(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro na validação',
          description: 'Ocorreu um erro ao tentar validar',
        });
      }
    };

  return (
    <AnimatedDiv>
      <LoginContent>
        <div>   <aside>
  

            <Form key={reloadKey} ref={formRef} initialData={defaultValue} onSubmit={handleNewLogin}>
              <h1>Atualizar Cadastro</h1>

              <Input icon={FiMail} name="company" placeholder="Empresa" />
              <Input icon={FiMail} name="position" placeholder="Cargo" />
              <MaskedInput mask="(99) 99999.9999" icon={FiMail} name="phone" placeholder="Telefone" />
      
            <MaskedInput
              mask={'999.999.999-99'}
              name="document_number"
              placeholder={'CPF'}
            />
    
            <MaskedInput
              mask="99/99/9999"
              name="birth_date"
              placeholder="Data de nascimento"
            />
             
              <Button type="submit">
               Atualizar

              
              </Button>
        
            </Form>
          </aside>
        </div>
      </LoginContent>
    </AnimatedDiv>
  );
};

export default LoginContainer;
