import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap } from 'react-icons/fi';
import { Link } from 'react-router-dom';


import { MdError } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../../core/components/Forms/Input';
import Button from '../../../../core/components/Forms/Button';
import Loader from '../../../../../../components/Loader';
import MaskedInput from '../../../../core/components/Forms/MaskedInput';
import { ResponseMessage } from '../../../../../../components/ResponseMessage';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../../../core/components/Forms/SelectSimple';
import Whatsapp from '../../../../../../components/WhatsAppCompartilhar';

import api from '../../../../../../services/api';
import { authTitle, recaptchaKey, urlWebsite } from '../../../../../../config';
import getCep from '../../../../../../services/cep';
import { FaBuilding, FaCheck, FaGenderless, FaIdBadge, FaPhone } from 'react-icons/fa';
import CheckboxInput from '../../../../core/components/Forms/CheckboxInput';
import { useLanguage } from '../../../../../../hooks/Language';
import { countries } from './countries';
import {universidades} from './universidades';
import { useAuth } from '../../../../../../hooks/Auth';
import TextareaInput from '../../../../core/components/Forms/TextareaInput';
import { useModal } from '../../../../../../hooks/Modal';
import { useToast } from '../../../../../../hooks/Toast';
import { AxiosInstance } from 'axios';
import { IYup } from '../../../../../../utils/SchemaCreator';
import { useSearch } from '../../../../../../hooks/Search';
import stringConverter from '../../../../../../utils/stringConverter';


interface IRecoverFormData {
  [key: string] : any
}

interface ICaptcha {
  getValue(): string;
}
declare global {
  interface Window {
    grecaptcha: any
  }
}

interface IProps {
  id : number;
}

interface IPaper {
  id ?: number;
  title ?: string;
  author ?: string;
  short_description ?: string;
  nested_keywords ?: string;
  category ?: string;
  area ?: string;
  subarea ?: string;
  keywords ?: Record<string,any>;
  highlight_response ?: Record<string,any>;
}
interface ICreateModule {
  lineData: Record<string, any>;

}


const Avaliation : React.FC<IProps> = ({ id}) => {

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
const {reloadSearchAll} = useSearch();
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const {translate} = useLanguage();



  const [conflito,setConflito] = useState('');
  const [apresentador,setApresentador] = useState('');


  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };

  const {removeModal,addModal} = useModal();
  const {addToast} = useToast();


  const [initialData,setInitialData] = useState<IPaper>({});


  const loadAvaliation = async (idPaper) => {
    setLoading(true);
    const response = await api.get(`/get-avaliation-paper/${idPaper}`);

    setLoading(false);
    if(response){
      setInitialData(response.data)
    }
    else{
      removeModal('avaliador');
      addToast({type: 'error', title: 'Trabalho não encontrado'});
    }
  }

  useEffect(() => {
    loadAvaliation(id);

  },[id])


  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);

    console.log(data);

    data.birth_date = data.birth_date
      ? data.birth_date.toString().replace(/[^0-9/]/g, '')
      : '';

    try {
      formRef.current?.setErrors({});

      const shape = {
        

        
        p1: Yup.string().required('Informe se você é co-autor ou orientador do trabalho'),
 

      };



      const schema = Yup.object().shape(shape);

      await schema.validate(data, { abortEarly: false });

 
      const answer : Record<string,any> = {};

      answer.questions = {...data};

      if(conflito === 'Sim'){
        answer.conflict = 2;
      }
        

      const response = await api.post(`/send-avaliation/${id}`, {
        ...answer
    
      });
      

      removeModal('avaliacao');

      setLoading(false);
      addToast({title: 'Avaliado com sucesso!', type: 'success'});
      reloadSearchAll();
    } catch (err) {
      window?.grecaptcha?.reset();
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (formRef && formRef.current && formRef.current.setErrors) {
          formRef.current.setErrors(errors);
        }
        setLoading(false);

        const errorsString = Object.keys(errors).reduce(
          (prev: Array<string>, key: string): Array<string> => {
            prev = [...prev, errors[key]];
            return prev;
          },
          [],
        );

        setErrorMessage(errorsString);

        return;
      }

      setErrorMessage([
        err?.response?.data?.message ||
          err?.response?.data?.error ||
          'Erro ao gerar sua avaliação',
      ]);
    }
    setLoading(false);
  };


  const box = (dataInfo,key = '') => {
    if(!dataInfo){
        return <></>
    }
    return <>
    {dataInfo.map( d => <aside style={{ background: '#eee',  padding: '5px', margin: '5px', color: '#333', width:'auto'}}>{key === 'self' ? d || '' : d?.[key] || ''}</aside>)}
    </>
}


  return <aside style={{maxWidth:'100%'}}>
  {loading === true && (
 <Loader message="carregando..." position="fixed" />
)}
<Form ref={formRef} onSubmit={handleNewLogin} >

<h2  style={{color: '#333'}}>Apresentador(a)</h2>
 <p style={{color: '#333', margin: '20px 0px', maxWidth: '500px'}} dangerouslySetInnerHTML={{__html: initialData && initialData?.author ? initialData?.author : ''}}/>


 <h2  style={{color: '#333'}}>Título</h2>
 <p style={{color: '#333', margin: '20px 0px', maxWidth: '500px'}} dangerouslySetInnerHTML={{__html: initialData && initialData.title ? initialData.title : ''}}/>

 <h2  style={{color: '#333'}}>Resumo</h2>
 <p style={{color: '#333', margin: '20px 0px', maxWidth: '500px'}} dangerouslySetInnerHTML={{__html: initialData && initialData.short_description ? stringConverter(initialData.short_description,'decode') : ''}}/>

 <h2  style={{color: '#333'}}>Palavras-chave</h2>
  <p>{initialData.keywords && box(initialData.keywords, 'title')}</p>

 <h2  style={{color: '#333'}}>Avaliação da apresentação oral</h2>



 <p style={{color: '#333', margin: '20px 0px', maxWidth: '500px'}}>1. O avaliador é co-autor ou orientador do trabalho?</p>
<SelectSimple label="" setCallback={(value) => setConflito(value)} name="p1" options={[
   { value: 'Sim', label: 'Sim'},
   { value: 'Não', label: 'Não'}       
 ]} />

 { conflito === 'Sim' && 
    <p style={{color: '#333', margin: '20px 0px', maxWidth: '500px'}}> 
    Devido ao conflito de interesse, solicitamos que finalize sem avaliar o trabalho.
    </p>
}

{
  conflito === 'Não' && <>
  <p style={{color: '#333', margin: '20px 0px', maxWidth: '500px'}}>2. O apresentador é o primeiro autor do trabalho?</p>

    <SelectSimple label="" setCallback={(value) => setApresentador(value)} name="p2" options={[
   { value: 'Sim', label: 'Sim'},
   { value: 'Não', label: 'Não'}       
 ]} />

 { apresentador === 'Não' && <Input name="apresentador" placeholder="Nome do apresentador"/> }



<p style={{color: '#333', margin: '20px 0px', maxWidth: '500px'}}>3. O apresentador apresenta postura e linguagem apropriada para a exposição oral do seu trabalho?</p>

<SelectSimple label="" name="p3" options={[
{ value: 'Sim', label: 'Sim'},
{ value: 'Não', label: 'Não'}       
]} />

<p style={{color: '#333', margin: '20px 0px', maxWidth: '500px'}}>4. O apresentador expressa-se de forma clara e objetiva?</p>

<SelectSimple label="" name="p4" options={[
{ value: 'Sim', label: 'Sim'},
{ value: 'Não', label: 'Não'}       
]} />

<p style={{color: '#333', margin: '20px 0px', maxWidth: '500px'}}>5. Foi apresentado material audiovisual de qualidade satisfatória?</p>

<SelectSimple label="" name="p5" options={[
{ value: 'Sim', label: 'Sim'},
{ value: 'Não', label: 'Não'}       
]} />

<p style={{color: '#333', margin: '20px 0px', maxWidth: '500px'}}>6. O apresentador demonstra domínio do assunto?</p>

<SelectSimple label="" name="p6" options={[
{ value: 'Sim', label: 'Sim'},
{ value: 'Não', label: 'Não'}       
]} />

<p style={{color: '#333', margin: '20px 0px', maxWidth: '500px'}}>7. O apresentador utilizou o tempo de forma adequada?</p>

<SelectSimple label="" name="p7" options={[
{ value: 'Sim', label: 'Sim'},
{ value: 'Não', label: 'Não'}       
]} />

<h2  style={{color: '#333'}}>Avaliação do resumo</h2>

<p style={{color: '#333', margin: '20px 0px', maxWidth: '500px'}}>8. O resumo está adequado às Instruções para Elaboração de Resumos constantes no Anexo 2 do Regulamento para Inscrição de Trabalhos no II Congresso UFCSPA?</p>

<SelectSimple label="" name="p8" options={[
{ value: 'Sim', label: 'Sim'},
{ value: 'Não', label: 'Não'}       
]} />

<p style={{color: '#333', margin: '20px 0px', maxWidth: '500px'}}>9. O título do trabalho é adequado ao objeto de estudo?</p>

<SelectSimple label="" name="p9" options={[
{ value: 'Sim', label: 'Sim'},
{ value: 'Não', label: 'Não'}       
]} />

<p style={{color: '#333', margin: '20px 0px', maxWidth: '500px'}}>10. A metodologia está adequada aos objetivos propostos e as condições de viabilidade?</p>

<SelectSimple label="" name="p10" options={[
{ value: 'Sim', label: 'Sim'},
{ value: 'Não', label: 'Não'}       
]} />

<p style={{color: '#333', margin: '20px 0px', maxWidth: '500px'}}>11. Os resultados obtidos ou esperados estão adequados ao objetivo proposto?</p>

<SelectSimple label="" name="p11" options={[
{ value: 'Sim', label: 'Sim'},
{ value: 'Não', label: 'Não'}       
]} />

<p style={{color: '#333', margin: '20px 0px', maxWidth: '500px'}}>12. São apontadas conclusões, justificativas ou perspectivas futuras?</p>

<SelectSimple label="" name="p12" options={[
{ value: 'Sim', label: 'Sim'},
{ value: 'Não', label: 'Não'}       
]} />
  </>
}

 {errorMessage && (
   <Error>
     {errorMessage.map((item, index) => (
       <div key={`error${index}`}>
         <MdError />
         {item}
       </div>
     ))}
   </Error>
 )}

<aside style={{display:'flex', justifyContent:'center'}}>
 <Button style={{ width: '100%' }} type="submit">
   Finalizar avaliação
     </Button> 
</aside>
</Form>

</aside>

}

export default function UpdateModule({

  lineData,

}: ICreateModule): JSX.Element {
  

  const {removeModal,addModal} = useModal();

  const callAvaliation = (id) => {

    
    

    addModal({theme: "whiteModal", title: 'Avaliação',key: 'avaliacao', content:   <Avaliation id={lineData.id}/>})

  }


  const moduleCreate = (): JSX.Element => { 

    if(!lineData?.status_id_response?.id){
      return <p>Erro ao identificar se foi avaliado</p>;
    }

   return <>{lineData?.status_id_response?.id === 1 ? <button className="defaultButtonMini" style={{margin: '10px auto'}} title="Avaliar trabalho" type="button" onClick={() => callAvaliation(lineData.id)}>
      Avaliar
    </button> :  lineData?.highlight_response?.id === 2 ? <> </> : <h2 style={{display:'block', margin: '10px', border: '1px solid #333', padding: '3px'}}>Avaliado</h2>}</>
  } ;

  return moduleCreate();


};


