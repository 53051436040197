import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap } from 'react-icons/fi';
import { Link } from 'react-router-dom';


import { MdError } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../../core/components/Forms/Input';
import Button from '../../../../core/components/Forms/Button';
import Loader from '../../../../../../components/Loader';
import MaskedInput from '../../../../core/components/Forms/MaskedInput';
import { ResponseMessage } from '../../../../../../components/ResponseMessage';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../../../core/components/Forms/SelectSimple';
import Whatsapp from '../../../../../../components/WhatsAppCompartilhar';

import api from '../../../../../../services/api';
import { authTitle, recaptchaKey, urlWebsite } from '../../../../../../config';
import getCep from '../../../../../../services/cep';
import { FaBuilding, FaCheck, FaGenderless, FaIdBadge, FaPhone } from 'react-icons/fa';
import CheckboxInput from '../../../../core/components/Forms/CheckboxInput';
import { useLanguage } from '../../../../../../hooks/Language';
import { countries } from './countries';
import {universidades} from './universidades';
import { useAuth } from '../../../../../../hooks/Auth';
import TextareaInput from '../../../../core/components/Forms/TextareaInput';
import { useModal } from '../../../../../../hooks/Modal';
import { useToast } from '../../../../../../hooks/Toast';
import { AxiosInstance } from 'axios';
import { IYup } from '../../../../../../utils/SchemaCreator';
import { useSearch } from '../../../../../../hooks/Search';
import stringConverter from '../../../../../../utils/stringConverter';


interface IRecoverFormData {
  [key: string] : any
}

interface ICaptcha {
  getValue(): string;
}
declare global {
  interface Window {
    grecaptcha: any
  }
}

interface IProps {
  id : number;
}

interface IPaper {
  id ?: number;
  title ?: string;
  short_description ?: string;
  nested_keywords ?: string;
  category ?: string;
  area ?: string;
  subarea ?: string;
  keywords ?: Record<string,any>;
}
interface ICreateModule {
  lineData: Record<string, any>;

}


const Avaliation : React.FC<IProps> = ({ id}) => {

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
const {reloadSearchAll} = useSearch();
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const {translate} = useLanguage();
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };

  const {removeModal,addModal} = useModal();
  const {addToast} = useToast();


  const [initialData,setInitialData] = useState<IPaper>({});


  const loadAvaliation = async (idPaper) => {
    setLoading(true);
    const response = await api.get(`/get-avaliation-paper/${idPaper}`);

    setLoading(false);
    if(response){
      setInitialData(response.data)
    }
    else{
      removeModal('avaliador');
      addToast({type: 'error', title: 'Trabalho não encontrado'});
    }
  }

  useEffect(() => {
    loadAvaliation(id);

  },[id])


  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);

    console.log(data);

    data.birth_date = data.birth_date
      ? data.birth_date.toString().replace(/[^0-9/]/g, '')
      : '';

    try {
      formRef.current?.setErrors({});

      const shape = {
        

        
        approved_id: Yup.string().required('Preencha a resposta: Você considera este trabalho apto para publicação nos anais do II Congresso UFCSPA?'),
 

      };



      const schema = Yup.object().shape(shape);

      await schema.validate(data, { abortEarly: false });

 
 
        

      const response = await api.post(`/send-avaliation/${id}`, {
        approved_id : data.approved_id,
        observations : data.observations
      });
      

      removeModal('avaliacao');

      setLoading(false);
      addToast({title: 'Avaliado com sucesso!', type: 'success'});
      reloadSearchAll();
    } catch (err) {
      window?.grecaptcha?.reset();
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (formRef && formRef.current && formRef.current.setErrors) {
          formRef.current.setErrors(errors);
        }
        setLoading(false);

        const errorsString = Object.keys(errors).reduce(
          (prev: Array<string>, key: string): Array<string> => {
            prev = [...prev, errors[key]];
            return prev;
          },
          [],
        );

        setErrorMessage(errorsString);

        return;
      }

      setErrorMessage([
        err?.response?.data?.message ||
          err?.response?.data?.error ||
          'Erro ao gerar sua avaliação',
      ]);
    }
    setLoading(false);
  };


  const box = (dataInfo,key = '') => {
    if(!dataInfo){
        return <></>
    }
    return <>
    {dataInfo.map( d => <aside style={{ background: '#eee',  padding: '5px', margin: '5px', color: '#333', width:'auto'}}>{key === 'self' ? d || '' : d?.[key] || ''}</aside>)}
    </>
}


  return <aside style={{maxWidth:'100%'}}>
  {loading === true && (
 <Loader message="carregando..." position="fixed" />
)}
<Form ref={formRef} onSubmit={handleNewLogin} >

 <h2  style={{color: '#333'}}>Título</h2>
 <p style={{color: '#333', margin: '20px 0px', maxWidth: '500px'}} dangerouslySetInnerHTML={{__html: initialData && initialData.title ? initialData.title : ''}}/>

 <h2  style={{color: '#333'}}>Resumo</h2>
 <p style={{color: '#333', margin: '20px 0px', maxWidth: '500px'}} dangerouslySetInnerHTML={{__html: initialData && initialData.short_description ? stringConverter(initialData.short_description,'decode') : ''}}/>

 <h2  style={{color: '#333'}}>Palavras-chave</h2>
 <p style={{padding: '20px',color: '#333'}}>Avaliador: Devido ao novo formato de inserção de palavras-chave, fica dispensada a validação de separadores nas palavras-chave</p>
  <p>{initialData.keywords && box(initialData.keywords, 'title')}</p>

 <h2  style={{color: '#333'}}>Avaliação</h2>


 <p style={{color: '#333', margin: '20px 0px', maxWidth: '500px'}}>Você considera este trabalho apto para publicação nos anais do II Congresso UFCSPA? </p>
<SelectSimple label="" name="approved_id" options={[
   { value: '2', label: 'Sim'},
   { value: '3', label: 'Não'}       
 ]} />


 <p style={{color: '#333', margin: '20px 0px', maxWidth: '500px'}}>Apontamentos para a comissão organizadora</p>
<TextareaInput name="observations"/>



 {errorMessage && (
   <Error>
     {errorMessage.map((item, index) => (
       <div key={`error${index}`}>
         <MdError />
         {item}
       </div>
     ))}
   </Error>
 )}

<aside style={{display:'flex', justifyContent:'center'}}>
 <Button style={{ width: '100%' }} type="submit">
   Finalizar avaliação
 </Button>
</aside>
</Form>

</aside>

}

export default function UpdateModule({

  lineData,

}: ICreateModule): JSX.Element {
  

  const {removeModal,addModal} = useModal();
  const {addToast} = useToast();
  const {reloadSearchAll} = useSearch();

  const sendHighlight = async (id) => {

    try{
    const response = await api.put(`/highlight-avaliation/${id}`);
    reloadSearchAll();
    addToast({title: 'Atualizado com sucesso', type: 'success'});

    }
    catch(err){
      addToast({title: 'Erro ao atualizar', type: 'error'});
    }

  }


  const moduleCreate = (): JSX.Element => { 

    if(!lineData?.status_id_response?.id){
      return <p>Erro ao identificar se foi avaliado</p>;
    }

   return <>
   
   {lineData?.status_id_response?.id === 2 && lineData?.conflict_response?.id === 1 && lineData?.highlight_response?.id === 1 ? <><button style={{margin: '10px'}} className="defaultButtonMini"  title="Destacar Trabalho" type="button" onClick={() => sendHighlight(lineData.id)}>
      Destacar trabalho
    </button> </>: <></>}
    
    {lineData?.highlight_response?.id === 2 && <h2 style={{display:'block', margin: '10px', border: '1px solid #333', padding: '3px'}}>Destaque</h2>}
    
    </>
  } ;

  return moduleCreate();


};


