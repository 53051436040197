import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../../../core/components/SearchComponent';

import lineModules from '../../../../core/components/lineModules'
import { Container } from './styles';
import { useSocket } from '../../../../../../hooks/Socket';
import { Button } from '../../../../core/components/Forms/Button/styles';

import review from './modules/Review';


interface ICreateModule {
  lineData: Record<string, any>;

 
  
}

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const CientificSubmission: React.FC = () => {
  const endpoint = '/cientific-submissions';
const {socket} = useSocket();
const title = 'Submissão de Trabalhos';


const schema: Record<string, any> = {
  id: { ref: 'id', column: 'id', label: 'ID' },
  hash: { ref: 'hash', column: 'hash', label: 'Hash', show: false },
  author: { ref: 'author', column: 'author', label: 'Autor'},
  author_institution: { ref: 'author_institution', column: 'author_institution', label: 'Instituição do autor'},
  author_email: { ref: 'author_email', column: 'author_email', label: 'Email do autor', show: false},
  author_document_number: { ref: 'author_document_number', column: 'author_document_number', label: 'CPF do autor', show: false},
  co_authors: { ref: 'co_authors', column: 'co_authors', label: 'Coautores', show: false},
  orientator: { ref: 'orientator', column: 'orientator', label: 'Orientador', show: false},
  orientator_institution: { ref: 'orientator_institution', column: 'orientator_institution', label: 'Instituição do Orientador', show: false},
  orientator_email: { ref: 'orientator_email', column: 'orientator_email', label: 'Email do Orientador', show: false},
  orientator_document_number: { ref: 'orientator_document_number', column: 'orientator_document_number', label: 'CPF do Orientador', show: false},
  title: { ref: 'title', column: 'title', label: 'Título' },
  short_description: { ref: 'short_description', column: 'short_description', label: 'Resumo', show: false },
  area: { ref: 'area', column: 'area', label: 'Área' },
  category: { ref: 'category', column: 'category', label: 'Categoria' },
  subarea: { ref: 'subarea', column: 'subarea', label: 'SubÁrea' },
  keywords: { ref: 'keywords', column: 'keywords', label: 'Palavras-chave', show: false  },
  presentation_time: { ref: 'presentation_time', column: 'presentation_time', label: 'Turno de apresentação', show: false  },
  have_agency: { ref: 'have_agency', column: 'have_agency', label: 'O trabalho tem financiamento de agência de fomento?', show: false  },
  agency: { ref: 'agency', column: 'agency', label: 'Agência de fomento', show: false  },
  status: {
    ref: 'status',
    column: 'status_response',
    includeColumn: 'title',
    label: 'Status',
    filter: true,
  },
  createdAt: {
    ref: 'created_at',
    column: 'createdAt',
    label: 'Data de Criação',
    mask: 'date',
  },
};


const formSchema = {
  
  status: {
    alias: 'status',
    model: 'selectApi',
    name: 'status',
    label: 'Status',

    endpoint,
  },
 
  title: {
    model: 'input',
    name: 'title',
    label: 'Título',
  },
  short_description: {
    model: 'richText2',
    name: 'short_description',
    label: 'Descritivo',
  },
  observations: {
    model: 'richText2',
    name: 'observations',
    label: 'Correções',
  },
  author: {
    model: 'input',
    name: 'author',
    label: 'Autor',
  },
  author_institution: {
    model: 'input',
    name: 'author_institution',
    label: 'Instituição do Autor',
  },

  author_email: {
    model: 'input',
    name: 'author_email',
    label: 'Email do Autor',
  },
  author_document_number: {
    model: 'input',
    name: 'author_document_number',
    label: 'CPF do Autor',
  },
  orientator: {
    model: 'input',
    name: 'orientator',
    label: 'Orientador',
  },
  orientator_institution: {
    model: 'input',
    name: 'orientator_institution',
    label: 'Instituição do Orientador',
  },

  

  orientator_email: {
    model: 'input',
    name: 'orientator_email',
    label: 'Email do Orientador',
  },

  orientator_document_number: {
    model: 'input',
    name: 'orientator_document_number',
    label: 'CPF do Orientador',
  },

  have_agency: {
    model: 'selectSimple',
    name: 'have_agency',
    label: 'Têm agência de Fomento?',
    options: [
      { label: 'Sim', value: 'Sim' },
      { label: 'Não', value: 'Não' },
    ],
  },

  agency: {
    model: 'input',
    name: 'agency',
    label: 'Qual agência?',
  },
};

  const formSchemaUpdate = {
    ...formSchema,
   
  };

  

  const formValidation: Record<string, ValidationSchema> = {

  };


   function updateLessonModule({

    lineData,
  
   
  }: ICreateModule): JSX.Element {

    const clearLessonCache = () => {
      socket.emit('clearChatCache', {room_id : lineData.id});
    }

return <Button style={{padding: '5px'}} onClick={() => clearLessonCache()}>Limpar Cache</Button>

  }
 


  const config: ISearchComponent = {
    idKey: endpoint,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
      lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        review({
          lineData,
       
        }),
  
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  const config2: ISearchComponent = {
    idKey: endpoint,
    title: 'Trabalhos submetidos',
    endpoint: endpoint,
    schema: schema,
    autoStart: true,
    headModules: [
 
      () =>
      lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
    
      (lineData: Record<string, any>) =>
      review({
        lineData,
     
      }),
          
          (lineData: Record<string, any>) =>
          lineModules.updateUnique({
            lineData,
            title,
            formSchema: formSchemaUpdate,
            validationSchema: {},
            endpoint,
          }),
        (lineData: Record<string, any>) =>
          lineModules.delete({
            lineData,
            title,
            formSchema,
            validationSchema: formValidation,
            endpoint,
          }),
    ],
  };

  return (
    <Container>

      <SearchComponent {...config2} />
    </Container>
  );
};

export default CientificSubmission;
