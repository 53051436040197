export const areas = [
 
  { 
      title: 'Simpósio de Ensino em Saúde',
      subname: 'area',
      subcategory : 'Áreas',

      description: 'Os trabalhos devem ser decorrentes de estudos/pesquisas desenvolvidos em Programas de Pós-Graduação em Ensino na Saúde.',
      lists : [
          { title : 'Estudo / Pesquisa desenvolvido em Programa de Pós-Graduação em Ensino na Saúde',
            lists : []
          },
 
      ]
   },


]