import React, { useEffect, useState } from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../../../core/components/SearchComponent';

import lineModules from '../../../../core/components/lineModules'
import { Container } from './styles';
import { useSocket } from '../../../../../../hooks/Socket';
import { Button } from '../../../../core/components/Forms/Button/styles';

import review from './modules/Review';

import{ areas} from '../../../../../Cientific/Pages/AvaliatorSubscription/areas';
import api from '../../../../../../services/api';


interface ICreateModule {
  lineData: Record<string, any>;

 
  
}

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

interface IContent {
  content : Record<string,any>;
  id : string;
  padding: number;
}

const CientificGridLine: React.FC<IContent> = ({ content, id, padding }) => {

  const [active,setActive] = useState(false)

  return <> <tr style={{cursor: 'pointer'}} onClick={() => setActive(!active)} key={id}><td style={{paddingLeft : `${padding}px`}}>{content?.title}</td><td>{content?.count ? content?.count : '0'}</td></tr>

  { active && content?.avaliators?.length > 0 && <tr><td colSpan={2}><table>{content?.avaliators.map( av => <tr key={id}><td colSpan={2}>{av?.name}</td><td>{av?.category}</td></tr>)}</table></td></tr>}





</>

}

function compare( a, b ) {
  if ( a.name.toLowerCase().trim() < b.name.toLowerCase().trim() ){
    return -1;
  }
  if ( a.name.toLowerCase().trim() > b.name.toLowerCase().trim() ){
    return 1;
  }
  return 0;
}

const CientificGrid: React.FC = () => {


const [areaList, setAreaList] = useState<Array<Record<string,any>>>([...areas]);

const load = async () => {
  const response = await api.get('/cientific-avaliators?limitItems=1000000');

  const references = {};

  if(response){
    /* items são os avaliadores */
    const avaliadores = response.data.rows;
    const newAreas : Array<Record<string,any>> = [...areas];

    avaliadores.map( i => {
      /* Área de atuação dos avaliadores */
      const themes = i.themes;

      themes.map(area => {


        const indexCategory = areas.findIndex( a => a.title === area.category);
        if(!newAreas[indexCategory].avaliators){
          newAreas[indexCategory].avaliators = [];
        }

        const findAvaliatorCategory = newAreas[indexCategory].avaliators.findIndex( av => av.id === i.id);

        if(findAvaliatorCategory < 0){
        newAreas[indexCategory].count = newAreas[indexCategory].count ? newAreas[indexCategory].count + 1 : 1;
        }

      

        

        if( findAvaliatorCategory < 0){
        newAreas[indexCategory].avaliators.push(i);
        }
       

            if(area.area){

              const indexArea = newAreas[indexCategory].lists.findIndex( b => b.title === area.area);

              if(!newAreas[indexCategory].lists[indexArea].avaliators){
                newAreas[indexCategory].lists[indexArea].avaliators = [];
              }

              const findAvaliatorArea = newAreas[indexCategory].lists[indexArea].avaliators.findIndex( av => av.id === i.id);

              if( findAvaliatorArea < 0){
              newAreas[indexCategory].lists[indexArea].count = newAreas[indexCategory].lists[indexArea].count ? newAreas[indexCategory].lists[indexArea].count + 1 : 1;
              }

           

              if( findAvaliatorArea < 0){
              newAreas[indexCategory].lists[indexArea].avaliators.push(i);
              }


                if(area.subarea){
                  const indexSubarea = newAreas[indexCategory].lists[indexArea].lists.findIndex( c => c.title === area.subarea);
                  
                  if(!newAreas[indexCategory].lists[indexArea].lists[indexSubarea].avaliators){
                    newAreas[indexCategory].lists[indexArea].lists[indexSubarea].avaliators = [];
                  }
                  const findAvaliatorSubarea = newAreas[indexCategory].lists[indexArea].lists[indexSubarea].avaliators.findIndex( av => av.id === i.id);

                  if(findAvaliatorSubarea < 0){
                  newAreas[indexCategory].lists[indexArea].lists[indexSubarea].count = newAreas[indexCategory].lists[indexArea].lists[indexSubarea].count ? newAreas[indexCategory].lists[indexArea].lists[indexSubarea].count + 1 : 1;
                  }
              
    
                  if(findAvaliatorSubarea < 0){
                  newAreas[indexCategory].lists[indexArea].lists[indexSubarea].avaliators.push(i);
                  }
                }


            }


/*

        const reference = `${ area.category } > ${area.area}${ area.subarea ? ` > ${area.subarea}` : '' }`;

        if(references[reference]){
          references[reference].count =  references[reference].count + 1;
          references[reference].avaliators.push(i);
   
        }
        else{
          references[reference] = {
            count : 1,
            avaliators: [ i ]
          }
        }

        */
        
      })
      

    })

    


    newAreas.map( (category,index) => {

      if(category.avaliators) {
        category.avaliators.sort( compare);
        }
    

      if(category?.lists?.length > 0){

        category?.lists.map( area => {

          if(area.avaliators) {
            area.avaliators.sort( compare);
            }
         

          if(area?.lists?.length > 0){

            area?.lists.map( subarea => {
              if(subarea.avaliators) {
              subarea.avaliators.sort( compare);
              }

            })

        }

      })

    }
    })


    

    setAreaList([...newAreas])
  }
}

useEffect(() => {
  load();
},[])

const renderList = () => {
  console.log(areaList);
return <table className="table">
{areaList && areaList.length > 0 &&  areaList.map( (category) => {
return <> <CientificGridLine padding={10} content={category} id={`category${category.title}`} />

  {category?.lists?.length > 0 && category?.lists.map( area => {

return <>
<CientificGridLine padding={25} content={area} id={`area${category.title}-${area.title}`} />

{area?.lists?.length > 0 && area?.lists.map( subarea => {

return <CientificGridLine padding={40} content={subarea} id={`subarea${category.title}-${area.title}-${subarea.title}`} />  
})}
</>
 


  })}
    


</>
})}

</table>

}

  return (
    <Container>

      <h2>Lista de Avaliadores / Áreas</h2>
      <p>Clique para visualizar os Avaliadores</p>
{renderList()}
    </Container>
  );
};

export default CientificGrid;
