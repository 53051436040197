import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../../../core/components/SearchComponent';

import lineModules from '../../../../core/components/lineModules'
import { Container } from './styles';
import { useSocket } from '../../../../../../hooks/Socket';
import { Button } from '../../../../core/components/Forms/Button/styles';

import review from '../../dashboardPages/CientificSubmission/modules/Review';
import update from '../../dashboardPages/CientificSubmission/modules/Update';

interface ICreateModule {
  lineData: Record<string, any>;

 
  
}

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const MyPapers: React.FC = () => {
  const endpoint = '/my-avaliated-papers';
const {socket} = useSocket();
const title = 'Meus trabalhos';


const schema: Record<string, any> = {
  id: { ref: 'id', column: 'id', label: 'ID' },
  title: { ref: 'title', column: 'title', label: 'Título' },
  short_description: { ref: 'short_description', column: 'short_description', label: 'Descritivo'},
  nested_keywords: { ref: 'nested_keywords', column: 'nested_keywords', label: 'Palavras-chave' },
  
};


  const config2: ISearchComponent = {
    idKey: endpoint,
    title: 'Trabalhos avaliados',
    endpoint: endpoint,
    schema: schema,
    autoStart: true,
    headModules: [],
    bodyModules: [
        
          
      
    ],
  };

  return (
    <Container>

      <SearchComponent {...config2} />
    </Container>
  );
};

export default MyPapers;
