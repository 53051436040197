import React from 'react';
import { FaArrowCircleRight } from 'react-icons/fa';
import stringConverter from '../../../../../../utils/stringConverter';
import {Container} from './style';
interface IProps {
    data : Record<string,any>
}

const Review : React.FC<IProps> = ({data}) => {

    const box = (dataInfo,key = '') => {
        if(!dataInfo){
            return <></>
        }
        return <>
        {dataInfo.map( d => <aside style={{ background: '#eee',  padding: '5px', margin: '5px', color: '#333', width:'auto'}}>{key === 'self' ? d || '' : d?.[key] || ''}</aside>)}
        </>
    }

    return <Container>
    <h2>1.1 - Dados do Autor</h2>
    <div className="reviewBlock">
        <strong>Nome completo</strong><p>{data?.author}</p>
        <strong>Email</strong><p>{data?.author_email}</p>
        <strong>Instituição</strong><p>{data?.author_institution}</p>
        </div>
        <h2>1.2 - Dados do Orientador</h2>
        <div className="reviewBlock">
        <strong>Nome completo</strong><p>{data?.orientator}</p>
        <strong>Email</strong><p>{data?.orientator_email}</p>
        <strong>Instituição</strong><p>{data?.orientator_institution}</p>
        <strong>CPF</strong><p>{data?.orientator_document_number}</p>
</div>
        <h2>1.3 - Demais autores</h2>
        <div className="reviewBlock">
        <strong>Nomes dos demais autores</strong><p>{box(data.co_authors, 'name')}</p>
</div>
        <h2>2 - Submissão do trabalho</h2>
        <div className="reviewBlock">
        <strong>Título</strong><p>{data?.title}</p>
        <strong>Resumo</strong><pre style={{    maxWidth: '100%',
    'whiteSpace': 'pre-wrap'}} dangerouslySetInnerHTML={{__html : stringConverter(data?.short_description,'decode')}}/>
</div>
        <h2>2.1 - Palavras-chave</h2>
        <div className="reviewBlock">
        <strong>Palavras-chave</strong><p>{box(data.keywords, 'title')}</p>
</div>
        <h2>2.2 - Agência de Fomento</h2>
        <div className="reviewBlock">
        <strong>O trabalho tem financiamento de agência de fomento?</strong>
        <p>{data?.have_agency}</p>

        <strong>Qual?</strong><p>{data?.agency}</p>
</div>
        <h2>3 - Categoria de submissão</h2>
        <div className="reviewBlock">
        <strong>Categoria</strong><p>{data?.category}</p>
        <strong>Área</strong><p>{data?.area}</p>
        <strong>Subárea</strong><p>{data?.subarea}</p>
</div>
        <h2>4 - Turno preferencial para apresentação oral</h2>
        <div className="reviewBlock">
        <strong>Turno</strong><p>{box(data.presentation_time, 'self')}</p>
        </div>
        </Container>
}

export default Review;