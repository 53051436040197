import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../../../core/components/SearchComponent';

import lineModules from '../../../../core/components/lineModules'
import { Container } from './styles';
import { useSocket } from '../../../../../../hooks/Socket';
import { Button } from '../../../../core/components/Forms/Button/styles';

import review from './modules/Review';


interface ICreateModule {
  lineData: Record<string, any>;

 
  
}

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const CientificNotification: React.FC = () => {
  const endpoint = '/cientific-notifications';
const {socket} = useSocket();
const title = 'Notificações';


const schema: Record<string, any> = {
  id: { ref: 'id', column: 'id', label: 'ID' },
  reference: { ref: 'reference', column: 'reference', label: 'Referência' },
  title: { ref: 'title', column: 'title', label: 'Título'},
  observations: { ref: 'observations', column: 'observations', label: 'Descritivo'},

  client_id: {
    ref: 'client_id',
    column: 'client_id_response',
    includeColumn: 'name',
    label: 'Enviado por',
    filter: true,
  },
  createdAt: {
    ref: 'created_at',
    column: 'createdAt',
    label: 'Data de Criação',
    mask: 'date',
  },
};


const formSchema = {

  
  reference: {
    alias: 'reference',
    model: 'input',
    name: 'reference',
    label: 'Referência',
  },
  title: {
    alias: 'title',
    model: 'input',
    name: 'title',
    label: 'Título',
  },
  observations: {
    alias: 'observations',
    model: 'input',
    name: 'observations',
    label: 'Descritivo',
  },
  client_id: {
    alias: 'client_id',
    model: 'selectApi',
    name: 'client_id',
    label: 'Enviado por',

    endpoint,
  },
 
};

  const formSchemaUpdate = {
    ...formSchema,
   
  };

  

  const formValidation: Record<string, ValidationSchema> = {

  };


   function updateLessonModule({

    lineData,
  
   
  }: ICreateModule): JSX.Element {

    const clearLessonCache = () => {
      socket.emit('clearChatCache', {room_id : lineData.id});
    }

return <Button style={{padding: '5px'}} onClick={() => clearLessonCache()}>Limpar Cache</Button>

  }
 


  const config2: ISearchComponent = {
    idKey: endpoint,
    title:title,
    endpoint: endpoint,
    schema: schema,
    autoStart: true,
    headModules: [
 
      () =>
      lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
  
          
          (lineData: Record<string, any>) =>
          lineModules.updateUnique({
            lineData,
            title,
            formSchema: formSchemaUpdate,
            validationSchema: {},
            endpoint,
          }),
        (lineData: Record<string, any>) =>
          lineModules.delete({
            lineData,
            title,
            formSchema,
            validationSchema: formValidation,
            endpoint,
          }),
    ],
  };

  return (
    <Container>

      <SearchComponent {...config2} />
    </Container>
  );
};

export default CientificNotification;
