import React, { Children, useEffect, useState } from 'react';
import { urlWebsite } from '../../config';

import { Container } from './styles';

interface ISlide {
  url : string;
  link_url : string;
}

interface IVideoBackgroundProps {
  slides : Array<ISlide>;
  speed ?: number;
}

const ImageSlider: React.FC<IVideoBackgroundProps> = ({
  slides,
  speed = 10000

}) => {
  const [currentSlide,setCurrentSlide] = useState<ISlide>(slides[0] ? slides[0] : {} as ISlide);

  useEffect(() => {
    let x = 0;
    const length = slides.length - 1;

    setInterval(() => {
      x = x +1;
      if(x > length){
        x = 0;
      }

      setCurrentSlide(slides[x]);
    },speed)


  },[slides])

  const sendToLink = (link) => {
    window.open(
      link,
      '_blank' // <- This is what makes it open in a new window.
    );
   
  }

  return (
    <Container onClick={() => { return currentSlide?.link_url ? sendToLink(currentSlide?.link_url) : false  }} background={currentSlide?.url ? `${urlWebsite}/${currentSlide?.url}` : '' }/>
  );
};

export default ImageSlider;
