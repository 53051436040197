import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../../../core/components/SearchComponent';

import lineModules from '../../../../core/components/lineModules'
import { Container } from './styles';
import { useSocket } from '../../../../../../hooks/Socket';
import { Button } from '../../../../core/components/Forms/Button/styles';

import review from './modules/Review';
import avaliarTrabalho from './modules/AvaliarTrabalho';

interface ICreateModule {
  lineData: Record<string, any>;

 
  
}

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const CientificAvaliation: React.FC = () => {
  const endpoint = '/cientific-avaliations';
const {socket} = useSocket();
const title = 'Avaliação de trabalhos';


const schema: Record<string, any> = {
  id: { ref: 'id', column: 'id', label: 'ID' },
  avaliation_questions: { ref: 'avaliation_questions', column: 'avaliation_questions', label: 'Questões', show: false },
  avaliation_nested_questions: { ref: 'avaliation_nested_questions', column: 'avaliation_nested_questions', label: 'Questões'},
  observations: { ref: 'observations', column: 'observations', label: 'Observações'},
  observations_correction: { ref: 'observations_correction', column: 'observations_correction', label: 'Correções'},
  paper_id: {
    ref: 'paper_id',
    column: 'paper_id_response',
    includeColumn: 'title',
    label: 'Trabalho',
    filter: true,
  },
  category: { ref: 'category', column: 'category', label: 'Categoria'},
  area: { ref: 'area', column: 'area', label: 'Área'},
  subarea: { ref: 'subarea', column: 'subarea', label: 'Subárea'},
  orientator: { ref: 'orientator', column: 'orientator', label: 'Orientador'},
  avaliator_id: {
    ref: 'avaliator_id',
    column: 'avaliator_id_response',
    includeColumn: 'name',
    label: 'Avaliador',
    filter: true,
  },
  highlight: {
    ref: 'highlight',
    column: 'highlight_response',
    includeColumn: 'title',
    label: 'É Destaque?',
    filter: true,
  },
  conflict: {
    ref: 'conflict',
    column: 'conflict_response',
    includeColumn: 'title',
    label: 'Há conflito de interesse?',
    filter: true,
  },
  status_id: {
    ref: 'status_id',
    column: 'status_id_response',
    includeColumn: 'title',
    label: 'Foi avaliado?',
    filter: true,
  },
  approved_id: {
    ref: 'approved_id',
    column: 'approved_id_response',
    includeColumn: 'title',
    label: 'Status de aprovação',
    filter: true,
  },
  oral_paper_id: {
    ref: 'oral_paper_id',
    column: 'oral_paper_id_response',
    includeColumn: 'title',
    label: 'Indicado para apresentação oral?',
    filter: true,
  },
  createdAt: {
    ref: 'created_at',
    column: 'createdAt',
    label: 'Data de Criação',
    mask: 'date',
  },
};


const formSchema = {

  
  observations_correction: {
  
      model: 'textareaInput',
      name: 'observations_correction',
      label: 'Correções',
    },
  paper_id: {
    alias: 'paper_id',
    model: 'selectApi',
    name: 'paper_id',
    label: 'Trabalho',

    endpoint,
  },
  avaliator_id: {
    alias: 'avaliator_id',
    model: 'selectApi',
    name: 'avaliator_id',
    label: 'Avaliador',

    endpoint,
  },
  highlight: {
    alias: 'highlight',
    model: 'selectApi',
    name: 'highlight',
    label: 'É Destaque?',

    endpoint,
  },
  conflict: {
    alias: 'conflict',
    model: 'selectApi',
    name: 'conflict',
    label: 'Há conflito de interesse?',

    endpoint,
  },

  status_id: {
    alias: 'status_id',
    model: 'selectApi',
    name: 'status_id',
    label: 'Foi avaliado?',

    endpoint,
  },
  approved_id: {
    alias: 'approved_id',
    model: 'selectApi',
    name: 'approved_id',
    label: 'Status de avaliação',

    endpoint,
  },

  oral_paper_id: {
    alias: 'oral_paper_id',
    model: 'selectApi',
    name: 'oral_paper_id',
    label: 'Foi avaliado?',

    endpoint,
  },
 
};

  const formSchemaUpdate = {
    ...formSchema,
   
  };

  

  const formValidation: Record<string, ValidationSchema> = {

  };


   function updateLessonModule({

    lineData,
  
   
  }: ICreateModule): JSX.Element {

    const clearLessonCache = () => {
      socket.emit('clearChatCache', {room_id : lineData.id});
    }

return <Button style={{padding: '5px'}} onClick={() => clearLessonCache()}>Limpar Cache</Button>

  }
 


  const config: ISearchComponent = {
    idKey: endpoint,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
      lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
     
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  const config2: ISearchComponent = {
    idKey: endpoint,
    title: title,
    endpoint: endpoint,
    schema: schema,
    autoStart: true,
    headModules: [
 
      () =>
      lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
    
      (lineData: Record<string, any>) =>
      review({
        lineData,
     
      }),

          (lineData: Record<string, any>) =>
          lineModules.updateUnique({
            lineData,
            title,
            formSchema: formSchemaUpdate,
            validationSchema: {},
            endpoint,
          }),
        (lineData: Record<string, any>) =>
          lineModules.delete({
            lineData,
            title,
            formSchema,
            validationSchema: formValidation,
            endpoint,
          }),
    ],
  };

  return (
    <Container>

      <SearchComponent {...config2} />
    </Container>
  );
};

export default CientificAvaliation;
