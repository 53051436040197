import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { IconBaseProps } from 'react-icons';

import { useField, FormHandles } from '@unform/core';
import { Container, Field } from './styles';
import {Error } from '../styles';
import { FaPlusSquare, FaTrash } from 'react-icons/fa';
import { uuid } from 'uuidv4';
interface IListInput {
  label: string;
  name: string;
}

interface IInputProps {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  list: Array<IListInput>;
  placeholder: string;
}

const JsonList: React.FC<IInputProps> = ({
  name,
  icon: Icon,
  formRef,
  setCallback = () => {},
  list,
  ...rest
}): JSX.Element => {
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState(defaultValue || []);

  const addRow = () => {
    const newValue = [...value];

    const newLine = Object.keys(list).reduce((prev, key) => {
      prev[list[key].name] = '';
      return prev;
    }, { idHash : uuid()});



    newValue.push(newLine);

    setValue([...newValue]);
  };

  const removeIndex = (index) => {


    const newList = value.filter(
      (item, _, array) => {
        return array.indexOf(item) !== index;
      },
    );

   

    
      setValue([...newList]);


    

  

  }

  const printLine = (name, index, list) => {
    const inputs: Array<JSX.Element> = [];

    Object.keys(list).map((key) => {
      if(key !== 'idHash'){
      inputs.push(
        <Field>
          <span>{list[key].label}</span>

          <input
            placeholder={list[key].label}
            defaultValue={
              value && value[index] && value[index][list[key].name]
                ? value[index][list[key].name]
                : ''
            }
            onChange={e => {
              const newValue = [...value];

              if (!newValue[index]) {
                newValue[index] = {};
              }
              if (!newValue[index]) {
                newValue[index][list[key].name] = '';
              }

              newValue[index][list[key].name] = e.target.value;
              setValue([...newValue]);
            }}
            name={`${name}_${index}`}
          />

        
        </Field>,
      );
          }
    });

    inputs.push(  <div style={{width:'20px', cursor:'pointer'}} onClick={() => removeIndex(index)}>
    <FaTrash size={20} title="Remover"/>
  </div>)

    return inputs;
  };

  useEffect(() => {
    setValue(defaultValue || []);
  },[defaultValue])

  useEffect(() => {
    
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: undefined,
      setValue: (ref,value) => {
       
        setValue(value);
      },
      getValue: (ref: any) => {
        return JSON.parse(ref.value);
      },
    });
  }, [fieldName, registerField]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        name={name}
        value={JSON.stringify(value)}
      />
      <button type="button" className="defaultButtonReverse" style={{width:'100px', margin: '10px auto'}} onClick={() => addRow()}>
        <FaPlusSquare size={20} style={{marginRight: '10px'}}/> Adicionar
      </button>
      {value.map((line, index) => (
        <Container key={line.idHash}>{printLine(name, index, list)}</Container>
      ))}
    </div>
  );
};

export default JsonList;
