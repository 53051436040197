import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const Dashboard: React.FC = () => {
  const endpoint = '/expositors';
  const title = 'Expositores';
  const schema: Record<string, any> = {
    id: { ref: 'id', column: 'id', label: 'ID', show: false },
    link_name: { ref: 'link_name', column: 'link_name', label: 'URL' },
    title: { ref: 'title', column: 'title', label: 'Expositor' },
    allowed_users: {
      ref: 'allowed_users',
      column: 'allowed_users_response',
      includeColumn: 'name',
      label: 'Responsável',
      filter: true,
    },
    status: {
      ref: 'status',
      column: 'status_response',
      includeColumn: 'title',
      label: 'Status',
      filter: true,
    },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    category: {
      model: 'selectSimple',
      type: 'text',
      name: 'category',
      label: 'Categoria',
      defaultValue: 1,
      options: [
        { id: 1, value: 1, label: 'Expositor' },
        { id: 2, value: 2, label: 'Instituição' },
      ],
    },
    link_name: {
      model: 'input',
      type: 'text',
      name: 'link_name',
      label: 'URL',
    },
    title: { model: 'input', type: 'text', name: 'title', label: 'Título' },
    description: {
      model: 'richText2',
      type: 'text',
      name: 'description',
      label: 'Descritivo',
    },
    extra_text: {
      model: 'richText2',
      type: 'text',
      name: 'extra_text',
      label: 'Conteúdo adicional',
    },
    video_id: {
      model: 'input',
      type: 'text',
      name: 'video_id',
      label: 'ID do Vìdeo',
    },
    video_type: {
      model: 'selectSimple',
      name: 'video_type',
      label: 'Player de Vídeo',
      defaultValue: '1',
      options: [
        { label: 'Youtube', value: '1' },
        { label: 'Vimeo', value: '2' },
      ],
    },
    allowed_users: {
      alias: 'allowed_users',
      model: 'selectApi',
      name: 'allowed_users',
      label: 'Responsável',

      endpoint,
    },
    status: {
      alias: 'status',
      model: 'selectApi',
      name: 'status',
      label: 'status',

      endpoint,
    },
    social: {
      model: 'jsonList',
      type: 'text',
      name: 'social',
      label: 'Social Icons',
      list: [
        { label: 'Descritivo do Vídeo', name: 'video_description' },
        { label: 'Facebook', name: 'facebook' },
        { label: 'Instagram', name: 'instagram' },
        { label: 'Whatsapp', name: 'whatsapp' },
        { label: 'Mensagem do Whatsapp', name: 'whatsapp_message' },
        { label: 'Website', name: 'website' },
        { label: 'Youtube', name: 'youtube' },
        { label: 'Linkedin', name: 'linkedin' },
        { label: 'Twitter', name: 'twitter' },
        { label: 'Google Meet', name: 'meet' },
        { label: 'Hangout', name: 'hangout' },
        { label: 'Zoom', name: 'zoom' },
        { label: 'Email', name: 'email' },
        { label: 'Telefone', name: 'telefone' },
        { label: 'Cor de fundo da página', name: 'backgroundColor' },
        { label: 'Cor de Sobreposição', name: 'backgroundContainer' },
        { label: 'Cor da fonte', name: 'colorContainer' },
        { label: 'Link para a capa', name: 'link_image' },
        { label: 'Texto do Botão', name: 'button_text' },
        { label: 'Link do Botão', name: 'button_link' },
      ],
    },

  };

  const formSchemaUpdate = {
    ...formSchema,
  
    expositor_files: {
      model: 'imageGalleryInput',
      type: 'input',
      name: 'expositor_files',
      label: 'Arquivos de apoio',
      list: [
        { label: 'Imagem', name: 'url' },
        { label: 'Título', name: 'title' },
        { label: 'Descritivo', name: 'description' },
        { label: 'Link', name: 'link_url' },
        { label: 'Tipo 1 Quadrado, 2 Retangular', name: 'type' },
      ],
    },
    banner_gallery: {
      model: 'imageGalleryInput',
      type: 'input',
      name: 'banner_gallery',
      label: 'Banners Horizontais',
      list: [
        { label: 'Imagem', name: 'url' },
        { label: 'Título', name: 'title' },

        { label: 'Link', name: 'link_url' },
       
      ],
    },
    video_gallery: {
      model: 'jsonListMultiple',
      type: 'text',
      name: 'video_gallery',
      label: 'Galeria de Vídeos',
      list: {
        type_id: { label: 'Tipo', name: 'type_id' },
        video_id: { label: 'ID do Vídeo', name: 'video_id' },
      },
    },
    image_gallery: {
      model: 'imageGalleryInput',
      type: 'input',
      name: 'image_gallery',
      label: 'Galeria',
      list: [
        { label: 'Imagem', name: 'url' },
        { label: 'Título', name: 'title' },
        { label: 'Descritivo', name: 'description' },
        { label: 'Link', name: 'link_url' },
        { label: 'Tipo 1 Quadrado, 2 Retangular', name: 'type' },
      ],
    },
    background: {
      model: 'uploadImage',
      type: 'upload',
      name: 'background',
      label: 'Background',
    },
    capa: {
      model: 'uploadImage',
      type: 'upload',
      name: 'capa',
      label: 'Capa',
    },
    logo: {
      model: 'uploadImage',
      type: 'upload',
      name: 'logo',
      label: 'Logo',
    },
  };

  const formValidation: Record<string, ValidationSchema> = {
    title: {
      name: 'title',
      type: 'string',
      label: 'Expositor',
      rules: [{ type: 'required' }],
    },
  };

  
  const config: ISearchComponent = {
    idKey: endpoint,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
      lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Dashboard;
