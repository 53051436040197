import React, { useState, useEffect } from 'react';

import { IconType } from 'react-icons/lib';

import { FaChevronDown, FaChevronUp, FaList } from 'react-icons/fa';
import { FiGrid } from 'react-icons/fi';
import { Container, ContainerWhite, Evento } from './styles';

import { SpeakersContainer } from '../../components/SpeakersGrid';
import api from '../../services/api';

interface ProgramData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Speakers: React.FC = () => {
  const [speakersData, setSpeakerData] = useState([]);

  useEffect(() => {
    const load = async () => {
      const order = { orderBy: 'name', direction: 'asc' };

      const string = `order=${encodeURIComponent(JSON.stringify(order))}`;

      const response = await api.get(`/speakers-list`);
      if (response) {
        setSpeakerData(response.data.rows);
      }
    };
    load();
  }, []);

  return <SpeakersContainer speakers={speakersData} />;
};

export default Speakers;
