import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../../../core/components/SearchComponent';

import lineModules from '../../../../core/components/lineModules'
import { Container } from './styles';
import { useSocket } from '../../../../../../hooks/Socket';
import { Button } from '../../../../core/components/Forms/Button/styles';

import review from '../../dashboardPages/CientificSubmission/modules/Review';
import avaliarTrabalhos from '../../modules/AvaliarTrabalho';
import correcao from '../../modules/Correcao';
import update from '../../dashboardPages/CientificSubmission/modules/Update';

interface ICreateModule {
  lineData: Record<string, any>;

 
  
}

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const MyPapers: React.FC = () => {
  const endpoint = '/my-avaliations';
const {socket} = useSocket();
const title = 'Minhas avaliações';


const schema: Record<string, any> = {
  id: { ref: 'id', column: 'id', label: 'ID' },
  reference: { ref: 'reference', column: 'reference', label: 'Etapa' },
  title: { ref: 'title', column: 'title', label: 'Título' },
  category: { ref: 'category', column: 'category', label: 'Categoria'},
  area: { ref: 'area', column: 'area', label: 'Área'},
  subarea: { ref: 'subarea', column: 'subarea', label: 'Subárea'},
  status_id: {
    ref: 'status_id',
    column: 'status_id_response',
    includeColumn: 'title',
    label: 'Já foi avaliado?',
    filter: true,
  },
  avaliator_id: {
    ref: 'avaliator_id',
    column: 'avaliator_id_response',
    includeColumn: 'name',
    label: 'Avaliador',

  },

};

/*
 (lineData: Record<string, any>) =>
          update({
            lineData,
         
          }),

          */

  const config2: ISearchComponent = {
    idKey: endpoint,
    title: 'Minhas avaliações',
    endpoint: endpoint,
    schema: schema,
    autoStart: true,
    headModules: [],
    bodyModules: [
          (lineData: Record<string, any>) =>
          review({
            lineData,
         
          }),
         
          
      
    ],
  };

  return (
    <Container>

      <SearchComponent {...config2} />
    </Container>
  );
};

export default MyPapers;
