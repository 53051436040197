import userEvent from '@testing-library/user-event';
import React, { useState, createContext, useContext, useEffect } from 'react';
import StateManager from 'react-select';

import { apiUrl, apiSocket } from '../config';
import { useAuth } from './Auth';

interface IPagesContextData {
  menu: Array<Record<string, any>>;
  pages: Array<Record<string, any>>;
  readPages: Function;
}

const PagesContext = createContext<IPagesContextData>({} as IPagesContextData);

const PagesProvider: React.FC = ({ children }) => {
  const {user} = useAuth();
  const [pages, setPages] = useState([
    { tag: 'home', status: 2 },
    { tag: 'patrocinadores', status: 1 },
    { tag: 'programacao', status: 1 },
    { tag: 'sobre', status: 1 },
    { tag: 'palestrantes', status: 1 },
    { tag: 'login', status: 2 },
    { tag: 'inscricao', status: 2 },
  ]);

  const [menu, setMenu] = useState([
    {
      title: 'Home',
      target: '/',
      targetID: '',
      fluid: 1,
      external: 1,
      status: 2,
    },
    {
      title: 'COMISSÃO',
      target: '/',
      targetID: 'comissao',
      fluid: 2,
      external: 1,
      status: 1,
    },
    {
      title: 'PROGRAMAÇÃO',
      target: '/programacao',
      targetID: '',
      fluid: 1,
      external: 1,
      status: 2,
    },


    {
      title: 'NORMAS PARA SUBMISSÃO',
      target: '/arquivos_apoio/normas_inscricao.pdf',
      targetID: '',
      fluid: 1,
      external: 2,
      status: 2,
    },
    {
      title: 'PALESTRAS',
      target: '/dashboard',
      targetID: '',
      fluid: 1,
      external: 1,
      status: user ? 2 : 1,
    },
    {
      title: 'NOTÍCIAS',
      target: '/noticias',
      targetID: '',
      fluid: 1,
      external: 1,
      status: 1,
    },
    {
      title: 'INSCRIÇÃO',
      target: '/inscricao',
      targetID: '',
      fluid: 1,
      external: 1,
      status: 2,
    },
    {
      title: 'TRABALHOS',
      target: '/poster',
      targetID: '',
      fluid: 1,
      external: 1,
      status: user ? 2 : 1,
    },
    {
      title: 'EXPOSITORES',
      target: '/exposicao',
      targetID: '',
      fluid: 1,
      external: 1,
      status: user ? 1 : 1,
    },
    {
      title: 'MOSTRA CULTURAL',
      target: '/mostra',
      targetID: '',
      fluid: 1,
      external: 1,
      status: user ? 1 : 1,
    },
    {
      title: 'OFICINAS',
      target: '/',
      targetID: '',
      fluid: 1,
      external: 1,
      status: 1,
    },
    {
      title: 'LOGIN DE AVALIADOR',
      target: '/login-avaliador',
      targetID: '',
      fluid: 1,
      external: 1,
      status: 2,
    },
  ]);

  useEffect(() => {


    setMenu( state => [
      {
        title: 'Home',
        target: '/',
        targetID: '',
        fluid: 1,
        external: 1,
        status: 2,
      },
      {
        title: 'COMISSÃO',
        target: '/',
        targetID: 'comissao',
        fluid: 2,
        external: 1,
        status: 1,
      },
     {
      title: 'PROGRAMAÇÃO',
      target: '/programacao',
      targetID: '',
      fluid: 1,
      external: 1,
      status: 2,
    },
      {
        title: 'NORMAS PARA SUBMISSÃO',
        target: '/arquivos_apoio/normas_inscricao.pdf',
        targetID: '',
        fluid: 1,
        external: 2,
        status: 2,
      },

      
      {
        title: 'PALESTRAS',
        target: '/dashboard',
        targetID: '',
        fluid: 1,
        external: 1,
        status: user ? 2 : 1,
      },
      {
        title: 'SUBMETER TRABALHO',
        target: '/trabalhos/submeter',
        targetID: '',
        fluid: 1,
        external: 2,
        status: user ? 2 : 1,
      },
      {
        title: 'NOTÍCIAS',
        target: '/noticias',
        targetID: '',
        fluid: 1,
        external: 1,
        status: 1,
      },
      {
        title: 'INSCRIÇÃO',
        target: '/inscricao',
        targetID: '',
        fluid: 1,
        external: 1,
        status: 2,
      },
      {
        title: 'TRABALHOS',
        target: '/poster',
        targetID: '',
        fluid: 1,
        external: 1,
        status: user ? 2 : 1,
      },
      {
        title: 'EXPOSITORES',
        target: '/exposicao',
        targetID: '',
        fluid: 1,
        external: 1,
        status: user ? 1 : 1,
      },
      {
        title: 'MOSTRA CULTURAL',
        target: '/mostra',
        targetID: '',
        fluid: 1,
        external: 1,
        status: user ? 1 : 1,
      },
      {
        title: 'OFICINAS',
        target: '/',
        targetID: '',
        fluid: 1,
        external: 1,
        status: 1,
      },
      {
        title: 'LOGIN DE AVALIADOR',
        target: '/login-avaliador',
        targetID: '',
        fluid: 1,
        external: 1,
        status: 2,
      },
    ]);

    if(user &&  (user?.profile === 1 || user?.profile === 4)){
      setMenu((state) => [...state,     {
        title: 'GERENCIADOR',
        target: '/manager',
        targetID: '',
        fluid: 1,
        external: 1,
        status: 2,
      }])
    }

  },[user])

  const readPages = () => {
    const pagesResponse = pages.reduce((prev, elem) => {
      prev[elem.tag] = elem;

      return prev;
    }, {});

    return pagesResponse;
  };

  return (
    <PagesContext.Provider value={{ pages, menu, readPages }}>
      {children}
    </PagesContext.Provider>
  );
};

function usePages(): IPagesContextData {
  const context = useContext(PagesContext);

  if (!context) {
    throw new Error('usePages must be used within a PagesProvider');
  }

  return context;
}

export { usePages, PagesProvider };
