import React, { useCallback, useRef, useEffect, useState } from 'react';
import {
  FiLogIn,
  FiMail,
  FiLock,
  FiKey,
  FiMessageCircle,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { rgb } from 'polished';
import {
  FaConnectdevelop,
  FaLock,
  FaHandPointUp,
  FaCertificate,
  FaTicketAlt,
  FaVideo,
  FaAward,
  FaSlideshare,
  FaKey,
} from 'react-icons/fa';
import { MdColorLens, MdMessage } from 'react-icons/md';
import { AiFillSafetyCertificate } from 'react-icons/ai';
import { IconType } from 'react-icons/lib';
import AreaExposicao from '../AreaExposicao';
import AreaInstitucional from '../AreaInstitucional';
import { ContactIcons, WhatsappIcon } from '../../components/Menu';
import Whatsapp from '../../components/Whatsapp';
import { logo } from '../../config';
import {
  Container,
  SecondaryContainer,
  ContainerWhite,
  GreyContainer,
  Capa,
  ProgramContainer,
  Sponsors,
  Photo,
} from './styles';

import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import { useModal } from '../../hooks/Modal';


import Publicas from './modules/publicas';

import { usePages } from '../../hooks/Pages';

import SponsorsList from '../Sponsors';
import Program from '../../components/Program';
import Youtube from '../../components/Youtube';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const SignIn: React.FC = () => {
  const history = useHistory();
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const { addModal, removeModal } = useModal();
  const formRef = useRef<FormHandles>(null);
  const [cardLayout, setCardLayout] = useState([] as Array<JSX.Element>);
  const { readPages } = usePages();

  const [pages, setPages] = useState(readPages());

  const handleSubmit = useCallback(
    async (data: SignInData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Email obrigatório')
            .email('Preencha um email válido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        const { email, password } = data;

        await signIn({ email, password });
        history.push('/dashboard');
        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login',
        });
      }
    },
    [signIn, addToast, history],
  );

  const buildCard: React.FC<IPublicData> = ({ icon, title, text }) => {
    return (
      <div
        onClick={() =>
          setCardLayout([<Publicas icon={icon} text={text} title={title} />])}
      >
        {icon({})}
        <p>{title}</p>
      </div>
    );
  };

  return (
    <>
      <Capa style={{alignItems:'center', justifyContent: 'center'}}>
        <div >
         <h2 style={{fontSize: '42px', maxWidth: '600px'}}>IV Simpósio Internacional de Ensino na Saúde - UFCSPA</h2>
        </div>

        <div className="" style={{ display: 'flex', justifyContent:'center', alignItems: 'center', flexDirection: 'column'}}>
        <a href="/certificados"><Button>Certificados</Button></a>
         
          <a href="/inscricao"><Button>Inscreva-se!</Button></a>
          <Link to="/login"><Button>Acesse a plataforma</Button></Link>
          <Link to="/programacao"><Button>Programação</Button></Link>
         
          
        </div>
      </Capa>
    </>
  );
};
export default SignIn;
