import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Container, CertificateLine } from './styles';
import Input from '../../components/Forms/Input';
import { useToast } from '../../hooks/Toast';
import { Button } from '../../components/Button/styles';
import api from '../../services/api';
import Loader from '../../components/Loader';
import { hash, urlWebsite } from '../../config';

interface ICertificates {
  certificate_id_response: { title: string };
  hash: string;
}

const Certificados: React.FC = () => {
  const [certificates, setCertificates] = useState<Array<ICertificates>>([]);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const verify = async data => {
    setLoading(true);
    if (!data.email) {
      setLoading(false);
      return addToast({ title: 'Preencha o campo email', type: 'error' });
    }

    const { email } = data;

    try{
    const response = await api.post(`/verify-certificates`, { email });

    if (!response) {
      setLoading(false);
      return addToast({
        title: 'Nenhum certificado encontrado',
        type: 'error',
      });
    }

    setCertificates(response.data.content);
    setActive(true);
    setLoading(false);

  }
  catch(err : any){
    setLoading(false);
    return addToast({
      title: err?.response?.data?.message || 'Erro ao identificar certificado',
      type: 'error',
    });
  }
  };

  const resetVerify = () => {
    setCertificates([]);
    setActive(false);
  };

  return (
    <Container>
      {!active && (
        <Form style={{ textAlign: 'center' }} onSubmit={verify} ref={formRef}>
          <h2 style={{ marginBottom: '20px' }}>Visualizar meus certificados</h2>
          <Input placeholder="Insira seu email" name="email" type="text" />
          <Button style={{ margin: '5px auto', width: '100%' }} type="submit">
            Verificar
          </Button>
        </Form>
      )}
      {active && (
        <>
          <h2 style={{ marginBottom: '20px' }}>Meus Certificados</h2>
          <div>
            {certificates && certificates.length > 0 ? (
              certificates.map(cert => (
                <CertificateLine>
                  <a
                    href={`https://api-certificados.encontrodigital.com.br/${hash}/certificados/${cert.hash}`}
                    target="_BLANK"
                  >
                    <button type="button">Visualizar</button>
                  </a>
                  <p>{cert?.certificate_id_response?.title || ''}</p>
                </CertificateLine>
              ))
            ) : (
              <p>Nenhum certificado encontrado</p>
            )}
          </div>

          <Button
            onClick={() => resetVerify()}
            style={{ margin: '5px auto', width: '100%' }}
            type="button"
          >
            Voltar
          </Button>
        </>
      )}

      {loading && <Loader message="Verificando" />}
    </Container>
  );
};
export default Certificados;
