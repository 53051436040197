import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';


import { MdError } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../../core/components/Forms/Input';
import Button from '../../../../core/components/Forms/Button';
import Loader from '../../../../../../components/Loader';
import MaskedInput from '../../../../core/components/Forms/MaskedInput';
import { ResponseMessage } from '../../../../../../components/ResponseMessage';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import { Error,Separator, Container, FixedResponse } from './styles';
import SelectSimple from '../../../../core/components/Forms/SelectSimple';
import Whatsapp from '../../../../../../components/WhatsAppCompartilhar';

import JsonListMultiple from '../../../../core/components/Forms/JsonListMultiple';

import api from '../../../../../../services/api';

import CheckboxInput from '../../../../core/components/Forms/CheckboxInput';
import { useLanguage } from '../../../../../../hooks/Language';
import TextareaInput from '../../../../core/components/Forms/TextareaInput';
import { areas } from './areas';
import CheckboxIncluder from './components/CategoryChange';

import { useToast } from '../../../../../../hooks/Toast';
import Review from '../../modules/Review';
import { rgb } from 'polished';


interface IRecoverFormData {
  [key : string ] : any
}

interface ICaptcha {
  getValue(): string;
}

interface IOption {
  label : string;
  value: string;
  id : string;
}

interface IParam {
  id : string;
}

const Submission : React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(true);
  const formRef = useRef<FormHandles>(null);

  const {id} = useParams<IParam>();

  const [submissionData,setSubmissionData] = useState<Record<string,any>>({});
  const recaptchaRef = useRef(null);
  const {translate} = useLanguage();
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };
const {addToast} = useToast();
  const [haveAgency, setHaveAgency] = useState('Não');
  const [activeReview, setActiveReview] = useState(false);
  
  const [category, setCategory] = useState('');
  const [area, setArea] = useState('');
  const [subarea, setSubarea] = useState('');
  const [countryISO, setCountryISO] = useState('BR');

  const [categoryOptions, setCategoryOptions] = useState<Array<IOption>>([]);
  const [areaOptions, setAreaOptions] = useState<Array<IOption>>([]);
  const [subareaOptions, setSubareaOptions] = useState<Array<IOption>>([]);

  const [initialData,setInitialData] = useState<Record<string,any>>({})


  const loadPaper =  async () => {
    if(id){
      const response = await api.get(`/getPaper/${id}`);

      if(response) {
        const {category : cat, area : a, subarea : s} = response.data;
        const data = {...response.data};
        delete data.category;
        delete data.area;
        delete data.subarea;
        setInitialData(data);
        setCategory(cat);
        setArea(a);
        setSubarea(s);

      }
    }
  }

  useEffect(() => {

    const areaOptions : Array<IOption> = [];

    areas.map( a => {
      areaOptions.push( { label: a.title, value : a.title, id: a.title });
    })

    setCategoryOptions(areaOptions);

    loadPaper();

  },[])

  useEffect(() => {

    const categoryIndex = areas.findIndex( a => a.title === category);
console.log('Categoria',category);
    if(categoryIndex < 0){
      setAreaOptions([]);
      setSubareaOptions([]);
    }
    else{

      const newAreas : Array<IOption> = [];

      areas?.[categoryIndex]?.lists?.map( c => {
        newAreas.push( { label: c.title, value : c.title, id: c.title })
      })
      setAreaOptions(newAreas);
      setSubareaOptions([]);
    }

  },[category])

  useEffect(() => {

    const categoryIndex = areas.findIndex( a => a.title === category);
    console.log('Area',area);
    if(categoryIndex < 0){
      setAreaOptions([]);
      setSubareaOptions([]);
    }
    else{

      const areaIndex = areas?.[categoryIndex]?.lists.findIndex( a => a.title === area);

      if(areaIndex < 0 ){
        setSubareaOptions([]);
      }
      else{

     
      const newSubareas : Array<IOption> = [];

      areas?.[categoryIndex]?.lists[areaIndex]?.lists?.map( c => {
        newSubareas.push( { label: c.title, value : c.title, id: c.title })
      })
      setSubareaOptions(newSubareas);
    }
    }

  },[area])
  

  const checkCategoria = (category,area) => {

    const categoryList = area.finIndex( a => a.title === category);

    if(!category){
      addToast({title: 'Campo categoria de submissão é obrigatório'});
      setLoading(false);
      return;
    }

  }
  
  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);

    try {
      formRef.current?.setErrors({});

      

      if( !activeReview){
      data.apresentacao_oral = data.apresentacao_oral?.[0] ? data.apresentacao_oral?.[0] : 'Sim';
      data.area = area;
      data.category = category;
      data.subarea = subarea;

      data.nested_keywords = data.keywords.reduce( ( prev,item) => { return prev !== '' ? `${prev}, ${item.title}` : item.title},'' );
      data.nested_co_authors = data.co_authors.reduce( ( prev,item) => {return prev !== '' ? `${prev}, ${item.name}` : item.name},'' );
      
      data.modalidade = data.modalidade?.[0] ? data.modalidade?.[0] : '';
      data.presentation_time = data.presentation_time?.[0] ? data.presentation_time : [];
      }

 
  
      const shape : Record<string,any> = {
        author : Yup.string().required('O campo Nome do autor é obrigatório'),
        author_email : Yup.string().required('O campo Email do autor é obrigatório'),
        author_institution : Yup.string().required('O campo Instituição do autor é obrigatório'),
        orientator : Yup.string().required('O campo Nome do orientador é obrigatório'),
        orientator_email : Yup.string().required('O campo Email do orientador é obrigatório'),
        orientator_institution : Yup.string().required('O campo Instituição do orientador é obrigatório'),
        orientator_document_number : Yup.string().required('O campo CPF do orientador é obrigatório'),
        title: Yup.string().required('O campo Título é obrigatório'),
        short_description: Yup.string().required('O campo Resumo é obrigatório'),
        nested_keywords: Yup.string().required('O campo Palavras-chave é obrigatório'),

        category: Yup.string().required('O campo Categoria de submissão é obrigatório'),
        area: Yup.string().required('O campo Área de submissão é obrigatório'),

       
      };


 

     

      const schema = Yup.object().shape(shape);

      await schema.validate(data, { abortEarly: false });

      if(data.presentation_time?.length < 1){
        setLoading(false);
        return  setErrorMessage(['Selecione ao menos 1 turno preferencial para apresentação oral']);
      }
      
      if(!activeReview){
        setSubmissionData(data);
        setActiveReview(true);
        setLoading(false);
        return ;
      }

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (formRef && formRef.current && formRef.current.setErrors) {
          formRef.current.setErrors(errors);
        }
        setLoading(false);

        const errorsString = Object.keys(errors).reduce(
          (prev: Array<string>, key: string): Array<string> => {
            prev = [...prev, errors[key]];
            return prev;
          },
          [],
        );

        setErrorMessage(errorsString);

        return;
      }

      setErrorMessage([
        err?.response?.data?.message ||
          err?.response?.data?.error ||
          'Erro ao gerar seu cadastro',
      ]);
    }
        
  }

    const sendSubmission = async() => {
setLoading(true);
try{

  if(id){
    await api.put(`/updatePaper/${id}`, {
      ...submissionData

  
    });
  }
  else{
    await api.post(`/submit-my-papers`, {
      ...submissionData

  
    });
  }
      

      setSuccess(true);
      setLoading(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (formRef && formRef.current && formRef.current.setErrors) {
          formRef.current.setErrors(errors);
        }
        setLoading(false);

        const errorsString = Object.keys(errors).reduce(
          (prev: Array<string>, key: string): Array<string> => {
            prev = [...prev, errors[key]];
            return prev;
          },
          [],
        );

        setErrorMessage(errorsString);

        return;
      }

      setErrorMessage([
        err?.response?.data?.message ||
          err?.response?.data?.error ||
          'Erro ao gerar seu cadastro',
      ]);
    }
    setLoading(false);
  };


    if(!id){
  return <Container style={{width:'100%'}}>
  <div style={{background: 'rgb(250,100,0)',padding:'20px', margin: '20px auto', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center' }}>
    <strong style={{width:'100%', textAlign:'center'}}>Período de submissão de trabalhos encerrado</strong>
    <p>Clique no botão "Meus Trabalhos" para adicionar seu pôster</p>
  </div></Container>
    }

  return (
    <>
      {loading === true && (
        <Loader message="Preparando seu cadastro..." position="fixed" />
      )}

      <aside style={{width:'100%'}}>
        {success ? (
         <aside style={{display:'flex', width:'100%', justifyContent:'center'}}> <ResponseMessage
            active
            type="success"
            title="Sucesso"
            description={
              <p>
                Trabalho submetido com sucesso!
                <br />
           
             
      
              </p>
            }
          /></aside>
        ) : (<Container style={{width:'100%'}}>
          <div style={{background: 'rgb(250,100,0)',padding:'20px', margin: '20px auto', display:'flex', justifyContent:'center' }}>
            <strong style={{width:'100%', textAlign:'center'}}>Nesta edição, somente serão aceitos trabalhos de alunos bolsistas de graduação da UFCSPA</strong>
          </div>
          <Form ref={formRef} onSubmit={handleNewLogin} initialData={initialData} style={{maxWidth:'800px'}}>
            <h1>Submissão de Trabalho</h1>

            {initialData.observations &&       <Separator>
              <p dangerouslySetInnerHTML={{__html: initialData.observations}}/>
            </Separator> }

            <Separator>
              <label>1.1 - Dados do Autor</label>
            <Input  name="author" placeholder="Nome Completo" />
            <Input  name="author_institution" placeholder="Sigla da Instituição" />
            <Input  name="author_email" placeholder="Email" />
            </Separator>

            <Separator>
              <label>1.2 - Dados do Orientador</label>
            <Input  name="orientator" placeholder="Nome Completo" />
            <Input  name="orientator_institution" placeholder="Sigla da Instituição" />
            <Input  name="orientator_email" placeholder="Email" />
            <Input  name="orientator_document_number" placeholder="CPF" />
            </Separator>

            <Separator>
              <label>1.3 - Demais autores</label>
<p className="tip">Inclua novamente os dados do orientador, caso seja um dos autores do trabalho.</p>
            <JsonListMultiple name="co_authors" placeholder="Demais autores" list={[
              { name: 'name', label:"Nome completo"},
              { name: 'institution', label:"Sigla da instituição"},
              { name: 'email', label:"Email"},
              { name: 'document_number', label:"CPF"}
            ]}/>
            </Separator>

            <Separator>
              <label>2 - Submissão do trabalho</label>
              <Input  name="title" placeholder="Título do trabalho" />
              <TextareaInput  limit={2000} name="short_description" placeholder="Resumo"/>
              </Separator>
              <Separator>
              <label>2.1 - Palavras-chave</label>
              <JsonListMultiple name="keywords" placeholder="Palavras-chave" list={[
              { name: 'title', label:"Palavra-chave"}
            ]}/>
            </Separator>
            <Separator>
              <label>2.2 - Agência de formento</label>

<SelectSimple name="have_agency" setCallback={(value) =>  {setHaveAgency(value || 'Não')}} label="O trabalho tem financiamento de agência de fomento? " options={[
              { value: 'Sim',  text: 'Sim',label: 'Sim, qual?'},
              { value: 'Não', text: 'Não', label: 'Não'},
           
            ]} />

{ haveAgency === 'Sim' && <Input name="agency"    placeholder="Qual a sua agência de fomento?" />}            
  
            </Separator>

            <Separator>
              <label>3 - Categoria de submissão</label>

             
         
              <CheckboxIncluder category={category} area={area} subarea={subarea} setSubarea={(value) => setSubarea(value)} setArea={(value) => setArea(value)} setCategory={(value) => setCategory(value)} name="category" options={areas} placeholder="Categorias" />

            </Separator>

            <Separator>
              <label>4 - Turnos preferenciais para apresentação oral</label>

              <CheckboxInput  name="presentation_time" options={
                [
                  { value : 'Manhã', id:'Manhã', label: 'Manhã'},
                  { value : 'Tarde', id:'Tarde', label: 'Tarde'},
                  { value : 'Noite', id:'Noite', label: 'Noite'}
                ]
              } placeholder="" />
              
            </Separator>

            {errorMessage && (
              <Error>
                {errorMessage.map((item, index) => (
                  <div key={`error${index}`}>
                    <MdError />
                    {item}
                  </div>
                ))}
              </Error>
            )}

<aside style={{display:'flex', justifyContent:'center'}}>
            <Button style={{ width: '100%' }} type="submit">
              Submeter
            </Button>
</aside>


          </Form>
          <FixedResponse style={{ display: activeReview ? 'flex' : 'none'}}>
        <div>
        <h1>Revisão de dados</h1>

        <Review data={submissionData}/>
   

        <button className="defaultButtonReverse" onClick={() => setActiveReview(false)} type="button" >Editar</button>
        <button className="defaultButton" onClick={() => sendSubmission()}>Enviar</button>
        </div>

        </FixedResponse>
          </Container>
        )}
      </aside>

     
    </>
  );
};

export default Submission;

