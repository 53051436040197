import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useSocket } from '../../../../hooks/Socket';
import { Button } from '../../core/components/Forms/Button/styles';
import speakerLink from './modules/speakerLink';
import moderatorLink from './modules/ModeratorLink';
import socketLink from './modules/UpdateLesson';
import { UpdateCoursesModule } from '../Courses';

interface ICreateModule {
  lineData: Record<string, any>;

 
  
}

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const Lesson: React.FC = () => {
  const endpoint = '/lessons';
const {socket} = useSocket();
const title = 'Palestras';


const formSchema = {
  
  reference: {
    model: 'selectSimple',
    name: 'reference',
    label: 'Sala de Referência',

    

    options: [
      { label: 'SALA 01', value: 'SALA 01'},
      { label: 'SALA 02', value: 'SALA 02'},
      { label: 'SALA 03', value: 'SALA 03'},
      { label: 'SALA 04', value: 'SALA 04'},
      { label: 'SALA 05', value: 'SALA 05'},
      { label: 'SALA 06', value: 'SALA 06'},
      { label: 'SALA 07', value: 'SALA 07'},
      { label: 'SALA 08', value: 'SALA 08'},
      { label: 'SALA 09', value: 'SALA 09'},
      { label: 'SALA 10', value: 'SALA 10'},
      { label: 'SALA 11', value: 'SALA 11'},
      { label: 'SALA 12', value: 'SALA 12'},
      { label: 'SALA 13', value: 'SALA 13'},
      { label: 'SALA 14', value: 'SALA 14'},
      { label: 'SALA 15', value: 'SALA 15'},
      { label: 'SALA 15', value: 'SALA 15'},
      { label: 'SALA 16', value: 'SALA 16'},
      { label: 'SALA 17', value: 'SALA 17'},
      { label: 'SALA 18', value: 'SALA 18'},
      { label: 'SALA 19', value: 'SALA 19'},
      { label: 'SALA 20', value: 'SALA 20'},
      


    ]
  
  },
  release_date: {
    model: 'datePickerHour',
    name: 'release_date',
    label: 'Data de Lançamento',
    defaultValue: '',
  },
  title: {
    model: 'input',
    name: 'title',
    label: 'Título',
    defaultValue: '',
  },
  start_hour: {
    model: 'input',
    name: 'start_hour',
    label: 'Horário',
    
    defaultValue: '',
  },
  time: {
    model: 'input',
    name: 'time',
    label: 'Iniciar o vídeo no tempo: Ex: 1m30s',
    
    defaultValue: '',
  },
  room_name: {
    model: 'selectSimple',
    name: 'SALA',
    label: 'room_name',
 
    options: [
      { label: 'Espaço 1', value: 'Espaço 1'},
      { label: 'Espaço 2', value: 'Espaço 2'},
      { label: 'Espaço 3', value: 'Espaço 3'},
      { label: 'Espaço 4', value: 'Espaço 4'},
      { label: 'Espaço 5', value: 'Espaço 5'},
      { label: 'Espaço 6', value: 'Espaço 6'},

    ]
  
  },
  room_color: {
    model: 'selectSimple',
    name: 'Cor',
    label: 'room_color',

    options: [
      { label: 'Espaço 1 - Azul ', value: 'rgb(19, 136, 253)'},
      { label: 'Espaço 2 - Coral', value: 'coral'},
      { label: 'Espaço 3 - Vermelho', value: 'rgb(239, 33, 87)'},
      { label: 'Espaço 4 - Roxo', value: 'rgb(179, 0, 255)'},
      { label: 'Espaço 5 - Amarelo', value: 'rgb(255, 222, 0)'},
      { label: 'Espaço 6 - Verde', value: 'rgb(159, 252, 1)'},
      { label: 'Espaço 1 - Verde', value: 'rgb(0, 255, 164)'},

    ]
  
  },
  category_name: {
    model: 'input',
    name: 'category_name',
    label: 'Subtítulo',
    defaultValue: '',
  },
  class_name: {
    model: 'selectSimple',
    name: 'class_name',
    label: 'Classe',

    options: [
      { label: 'Com texto', value: 'withText' },
      { label: 'Sem texto', value: 'notext' },


    ]
  
  },
  status: {
    model: 'selectSimple',
    name: 'status',
    label: 'Status',
    defaultValue: '1',
    options: [
      { label: 'Em breve', value: 1 },
      { label: 'Ao vivo', value: 2 },
      { label: 'Finalizada', value: 3 },

    ]
  
  },
  category_id: {
    alias: 'category_id',
    model: 'selectApi',
    name: 'category_id',
    label: 'Categoria',
    defaultValue: 1,
    endpoint,
  },
  dificulty_id: {
    alias: 'dificulty_id',
    model: 'selectApi',
    name: 'dificulty_id',
    label: 'Nível',
    defaultValue: 1,
    endpoint,
  },
  video_type_id: {
    alias: 'video_type_id',
    model: 'selectApi',
    name: 'video_type_id',
    label: 'Tipo de Vídeo',
    defaultValue: 1,
    endpoint,
  },
  video_id: {
    model: 'input',
    name: 'video_id',
    label: 'Código do Vídeo',
    defaultValue: '',
  },
  zoom_id: {
    model: 'input',
    name: 'zoom_id',
    label: 'ID do Zoom',
    defaultValue: '',
  },
  speakers : { name: 'speakers', type: 'text', model: 'ArrayInputIncluder', label: 'Email dos palestrantes' },
  chat_id: {
    model: 'input',
    name: 'chat_id',
    label: 'Chat de Referência',
    defaultValue: 0,
  },
  visible: {
    model: 'input',
    name: 'visible',
    label: 'Visível no Grid? 1 Não, 2 Sim',
    defaultValue: 2,
  },
  languages: {
    model: 'jsonList',
    type: 'text',
    name: 'languages',
    label: 'Línguas',
    list: [
      { label: 'Português', name: 'ptBr' },
      { label: 'Inglês', name: 'en' },
      { label: 'Espanhol', name: 'es' },
    ],
  },

  
};

  const formSchemaUpdate = {
    ...formSchema,
    icon: {
      model: 'uploadImage',
      type: 'upload',
      name: 'icon',
      label: 'Ícone',
    },
    image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'image',
      label: 'Foto',
    },
    background_image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'background_image',
      label: 'Fundo da Sala',
    }
  };

  

  const formValidation: Record<string, ValidationSchema> = {
    title: {
      name: 'title',
      type: 'string',
      label: 'Título',
      rules: [{ type: 'required' }],
    },
    video_id: {
      name: 'video_id',
      type: 'string',
      label: 'Código do Vìdeo',
      rules: [{ type: 'required' }],
    },
  };


  const schema: Record<string, any> = {
    id: { ref: 'id', column: 'id', label: 'ID' },
    hash: { ref: 'hash', column: 'hash', label: 'Hash', show: false },
    reference: { ref: 'reference', column: 'reference', label: 'SALA Referência' },
    title: { ref: 'title', column: 'title', label: 'Título' },
    room_name: { ref: 'room_name', column: 'room_name', label: 'SALA' },
    release_date: { ref: 'release_date', column: 'release_date', mask: 'date', label: 'Data' },
    start_hour: { ref: 'start_hour', column: 'start_hour', label: 'Horário' },
    
    video_type_id: {
      ref: 'video_type_id',
      column: 'video_type',
      includeColumn: 'title',
      label: 'Tipo de Vídeo',
    },
    video_id: { ref: 'video_id', column: 'video_id', label: 'ID do Vídeo' },
    class_name: { ref: 'class_name', column: 'class_name',  label: 'Classe' },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

   function updateLessonModule({

    lineData,
  
   
  }: ICreateModule): JSX.Element {

    const clearLessonCache = () => {
      socket.emit('clearChatCache', {room_id : lineData.id});
    }

return <Button style={{padding: '5px'}} onClick={() => clearLessonCache()}>Limpar Cache</Button>

  }
 


  const config: ISearchComponent = {
    idKey: endpoint,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
      lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
      speakerLink({
          lineData,
          
        }),
        (lineData: Record<string, any>) =>
        moderatorLink({
            lineData,
            
          }),
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  const config2: ISearchComponent = {
    idKey: endpoint,
    title: 'aulas',
    endpoint: '/lessons',
    schema: schema,
    autoStart: true,
    headModules: [
      () =>
      lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
      speakerLink({
          lineData,
          
        }),
        (lineData: Record<string, any>) =>
        moderatorLink({
            lineData,
            
          }),
          (lineData: Record<string, any>) =>
          socketLink({
              lineData,
              
            }), 
          
          (lineData: Record<string, any>) =>
          lineModules.updateUnique({
            lineData,
            title,
            formSchema: formSchemaUpdate,
            validationSchema: {},
            endpoint,
          }),
        (lineData: Record<string, any>) =>
          lineModules.delete({
            lineData,
            title,
            formSchema,
            validationSchema: formValidation,
            endpoint,
          }),
    ],
  };

  return (
    <Container>
      <UpdateCoursesModule/>
      <SearchComponent {...config2} />
    </Container>
  );
};

export default Lesson;
