import React, { useEffect, useRef, InputHTMLAttributes, useState } from 'react';
import { useField } from '@unform/core';
import { Container } from './styles';
import theme from '../../../../../../../../styles/ThemeComponents';



interface IList {
  title : string;
  subname ?: string;
  subcategory ?: string;
  lists ?: IList[];
  subdescription ?: string;
  description ?: string;
}

interface Props extends InputHTMLAttributes<HTMLInputElement> {

  style?: Record<string, any>;
  name: string;
  unique?: boolean;
  preText?: JSX.Element;
  posText?: JSX.Element;
  background_line ?: string;
  description ?: string;
  setCategory ?: Function;
  setArea?: Function;
  setSubarea?: Function;
  options: IList[];
  category ?: string;
  area ?: string;
  subarea ?: string;
}
const CheckboxIncluder: React.FC<Props> = ({

  name,
  options,
  preText = '',
  posText = '',
  unique = true,
  style,
  background_line = '',
  placeholder = '',
  description = '',
  setCategory = ( value ) => {},
  setArea = (value) => {},
  setSubarea = (value) => {},
  category = '',
  subarea = '',
  area = '',
  ...rest
}) => {
  let inputRefs = useRef<HTMLInputElement[]>([]);
  const [currentValue,setCurrentValue] = useState('');
  const { fieldName, registerField, defaultValue = [] } = useField(name);

  const [valid,setValid] = useState({
    category : '',
    area : '',
    subarea : ''
  })



  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
  
        
        return refs.filter(ref => ref.checked).map(ref => ref.value);
      },
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach(ref => {
          ref.checked = false;
        });
      },
      setValue: (refs: HTMLInputElement[], values: string[]) => {
        refs.forEach(ref => {
          if (values.includes(ref.id)) {
            ref.checked = true;
            
          }
        });
      },
    });


  }, [defaultValue, fieldName, registerField]);

  useEffect( () => {
    inputRefs.current.map( i => {
      if(i.name !== 'category'){
        i.checked = false;
      }
    })


  },[category])

  useEffect( () => {
    inputRefs.current.map( i => {
      if(i.name === 'subarea'){
        i.checked = false;
      }
    })


  },[area])

  useEffect(() => {

    setValid({category,area,subarea});

  },[category,area,subarea])

 
  return (
    <Container style={style} {...rest}>
      {preText && <label style={{ border: '0', textAlign: 'justify', color: theme.color }}>{preText}</label>}
      {placeholder && <h2 style={{ border: '0', textAlign: 'justify', color: theme.color }}>{placeholder}</h2>}
      {description && <p dangerouslySetInnerHTML={{__html: description}}/>}
      {options.map((option, index) => (<>
        <label style={{cursor: 'pointer', color: theme.color, width:'100%',  background: option?.title === valid[name]  ? 'rgb(0,255,255)' :'#fff', padding:"10px"}} htmlFor={`${name}_${option.title}`} key={`${name}_${option.title}`}>
          <input checked={option?.title === valid[name]} style={{color: theme.color, border: '1px solid#333'}}
            onClick={(e) => {
              if (unique) {
                inputRefs.current.forEach(ref => {
                  ref.checked = false;
                  
                });

                const value = e.currentTarget.value;
                setCurrentValue(value);
                inputRefs.current[index].checked = true;

       


                if(name === 'category'){ setCategory(value)
                setArea('');
                setSubarea('');
                };
                if(name === 'area'){ setArea(value);
                  setSubarea('');};
                if(name === 'subarea'){ setSubarea(value)};
              }
            }}
            defaultChecked={defaultValue.find((dv: string) => dv === option.title)}
            ref={ref => {
              inputRefs.current[index] = ref as HTMLInputElement;
            }}
            value={option.title}
            type="checkbox"
            id={`${name}_${option.title}`}
            name={name}
            {...rest}
          />
          <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'flex-start', marginLeft: '10px'}}>
          <strong style={{color: "#333", fontSize: '14px'}}>{option.title}</strong>
          {  option?.description && <p style={{color: "#333", fontSize: '14px'}} dangerouslySetInnerHTML={{__html : option.description || ''}}/>}
          </div>
        </label>
        {option?.lists && option?.subname && <div style={{display: option?.title === valid[name] ? 'block' : 'none', width: '100%', maxWidth:'100%', padding: '0px'}}><CheckboxIncluder category={category} area={area} subarea={subarea} setCategory={setCategory} setArea={setArea} setSubarea={setSubarea} name={option?.subname} placeholder={option.subcategory} description={option?.subdescription} options={option.lists}/></div> }   
        </>
      ))}
      {posText && <label style={{ border: '0', textAlign: 'justify', color: "#333" }}>{posText}</label>}
    </Container>
  );
};
export default CheckboxIncluder;
