import React from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import uuid from '../../../../../../core/components/UniqueHash';
import { useModal } from '../../../../../../../../hooks/Modal';
import FormComponentUnique, { sanitizeDataObject } from '../../../../../../core/components/FormComponentUnique';
import { createSchemaByForm, IYup } from '../../../../../../../../utils/SchemaCreator';
import getValidationErrors from '../../../../../../../../utils/getValidationErrors';
import api from '../../../../../../../../services/api';
import { useToast } from '../../../../../../../../hooks/Toast';
import { useSearch } from '../../../../../../../../hooks/Search';
import { AxiosInstance } from 'axios';
import { FaEdit } from 'react-icons/fa';
import { IconType } from 'react-icons/lib';

interface ICreateModule {
  lineData: Record<string, any>;
  title: string;
  formSchema: Record<string, any>;
  endpoint: string;
  validationSchema: Record<string, IYup>;
  apiRef ?: AxiosInstance,
  Icon ?: IconType,
  moduleTitle ?: string;
}

export default function UpdateModule({
  apiRef = api,
  lineData,
  title,
  formSchema,
  validationSchema,
  endpoint,
  Icon = FaEdit,
  moduleTitle = 'Editar'
}: ICreateModule): JSX.Element {
  const { reloadSearchAll } = useSearch();
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const key = uuid();

  const setCallback = async (
    data: Record<string, any>,
    formRef: React.RefObject<FormHandles>,
  ): Promise<void> => {
    try {
      const newData = sanitizeDataObject(data, formSchema);

      const schema = createSchemaByForm(validationSchema);
      await schema.validate(newData, { abortEarly: false });
      const column = Object.keys(data)[0];

      if (formSchema[column].type === 'upload') {
        const formData = new FormData();
    

        if(!data[column]?.type || ( data[column]?.type !== 'image/png' && data[column]?.type !== 'image/jpg' && data[column]?.type !== 'image/jpeg' )){
          addToast({type:'error', title: 'Tipo de arquivo inválido', description: 'Seu pôster deve ser exportado em jpg, png ou jpeg'});
        return;
        }

        if(data[column]?.size > 1000000){
          addToast({type:'error', title: 'Tamanho máximo excedido', description: 'O tamanho máximo do seu arquivo deve ser 1mb'});
          return;
        }

        formData.append('file', data[column]);

        const response = await apiRef.put(
          `/updatePaperPoster/${lineData.id}/${column}`,
          formData,
        );
        if (response.status !== 200) {
          return addToast({ type: 'error', title: 'Erro ao atualizar' });
        }
      } else {
        const response = await apiRef.put(
          `${endpoint}/${lineData.id}/${column}`,
          newData,
        );
        if (response.status !== 200) {
          return addToast({ type: 'error', title: 'Erro ao atualizar' });
        }
      }

      // removeModal(key);
      reloadSearchAll();

      addToast({ type: 'success', title: 'Atualizado com sucesso' });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
        return;
      }
console.log(err);
      return addToast({ type: 'error', title: 'erro' });
    }
  };

  const handleUpdate = async (id: string): Promise<void> => {
    const response = await api.get(`/getPaper/${id}`);
    const { data } = response;

    const content = (
      <FormComponentUnique
        dataInfo={data}
        schema={formSchema}
        setCallback={setCallback}
      />
    );
    if (content) {
      const keyModal = addModal({ title, content, key,theme : "whiteModal" });
    }
  };

  const moduleUpdate = (idUser: string): JSX.Element => (
    <button className="lineIcon"  title={moduleTitle} type="button" onClick={() => handleUpdate(idUser)}>
      <Icon />
    </button>
  );

  return moduleUpdate(lineData.id);
}
