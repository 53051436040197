import styled from 'styled-components';
import {ContainerRef} from '../styles';

export const Container = styled(ContainerRef)`

.ck-editor{
    width: 100%;
    margin-top: 10px;
}

.ck-editor__editable{
    min-height: 300px;
}
`;