import React, { useEffect, useState } from 'react';
import Button from '../../../../../../components/Forms/Button';
import Submission from '../Submission';
import {Container} from './styles';
import MyPapers from '../MyPapers';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { authTitle, supportNumber } from '../../../../../../config';
import Whatsapp from '../../../../../../components/Whatsapp';

interface IParams {
  tab : string;
}

const CientificHome: React.FC = () => {
const [type, setType] = useState('');
const {tab} = useParams<IParams>();

const [validTab,setValidTab] = useState({
  home : '',
  submeter : 'submeter'
});

useEffect(() => {
  if(tab && validTab[tab]){
    setType(validTab[tab]);
  }
},[tab])
  return (
    <>

      <Container >
        <h3>
          SUBMISSÃO DE TRABALHOS
        </h3>
<div style={{display:'flex', alignItems: 'center', flexWrap:'wrap', justifyContent:'center', padding: '10px'}}>
        <Link to="/trabalhos"><Button onClick={() => setType('')}>Meus trabalhos</Button></Link>
        <Link to="/trabalhos/submeter"><Button onClick={() => setType('submeter')}>Submeter trabalho</Button></Link>
        <a target="_BLANK" href="/arquivos_apoio/normas_inscricao.pdf"><Button>Normas para Submissão</Button></a>
        <a target="_BLANK" href="/arquivos_apoio/cronograma.pdf"><Button>Cronograma</Button></a>


        <a target="_BLANK" href="/arquivos_apoio/orientacoes_poster2.jpeg"><button style={{margin: '10px'}} className="defaultButtonReverse">Orientações para o pôster</button></a>
        <a target="_BLANK" href="/arquivos_apoio/regras_poster3.docx"><button style={{margin: '10px'}} className="defaultButtonReverse">Regras para submissão de pôster</button></a>
        <a target="_BLANK" href="/arquivos_apoio/modelo_poster.pptx"><button style={{margin: '10px'}} className="defaultButtonReverse">Modelo de pôster <br/>(PPT)</button></a>
        <a target="_BLANK" href="/arquivos_apoio/modelo_apresentacao.pptx"><button style={{margin: '10px'}} className="defaultButtonReverse">Modelo para apresentação <br/>(PPT)</button></a>
        <Whatsapp number={supportNumber} text={`${authTitle}: Quero submeter um trabalho`} message="Suporte Técnico"/>
        
        </div>
       
        { type === '' && <MyPapers/>}
        { type === 'submeter' && <Submission/>}

      </Container>
    </>
  );
};
export default CientificHome;
