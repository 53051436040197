import React, { useCallback, useRef, useEffect, useState } from 'react';
import {
  FiLogIn,
  FiMail,
  FiLock,
  FiKey,
  FiMessageCircle,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { rgb } from 'polished';
import {
  FaConnectdevelop,
  FaLock,
  FaHandPointUp,
  FaCertificate,
  FaTicketAlt,
  FaVideo,
  FaAward,
  FaSlideshare,
  FaKey,
} from 'react-icons/fa';
import { MdColorLens, MdMessage } from 'react-icons/md';
import { AiFillSafetyCertificate } from 'react-icons/ai';
import { IconType } from 'react-icons/lib';

import { ContactIcons, WhatsappIcon } from '../../components/Menu';
import Whatsapp from '../../components/Whatsapp';

import { Container, ContainerWhite } from './styles';

import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';


import ShowDiv from '../../components/ShowOnScroll';
import VideoBackground from '../../components/VideoBackground';
import TextPopper from '../../components/TextPopper';
import Scroller from '../../components/Scroller';

import Vimeo from '../../components/Vimeo';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Termos: React.FC = () => {
  return (
    <>
      <ContainerWhite id="termos">
        <div className="p50 p600" style={{ textAlign: 'justify' }}>
        <p style={{textAlign:'center', margin: '20px'}}>
    <strong>POLÍTICA DE PRIVACIDADE E PROTEÇÃO DE DADOS CONGRESSO UFCSPA</strong>
</p>
<strong style={{textAlign:'justify', marginTop: '20px'}}>1. Informações gerais</strong>
<p style={{textAlign:'justify', marginTop: '20px'}}>
A presente Política de Privacidade contém informações a respeito do modo como tratamos, total ou parcialmente, de forma automatizada ou não, os dados pessoais dos usuários que acessam nosso site. Seu objetivo é esclarecer os interessados acerca dos tipos de dados que são coletados, dos motivos da coleta e da forma como o usuário poderá atualizar, gerenciar ou excluir estas informações. Esta Política de Privacidade foi elaborada em conformidade com a Lei Federal n. 12.965 de 23 de abril de 2014 (Marco Civil da Internet), com a Lei Federal n. 13.709, de 14 de agosto de 2018 (Lei Geral de Proteção de Dados Pessoais - LGPD), com a Lei Federal n. 12.527, de 18 de novembro de 2011 (Lei de Acesso à Informação), com a Lei Federal n. 13.460, de 26 de junho de 2017 (Lei da participação, proteção e defesa dos direitos do usuário dos serviços públicos da administração pública), e com o Regulamento UE n. 2016/679 de 27 de abril de 2016 (Regulamento Geral Europeu de Proteção de Dados Pessoais - RGDP). Esta Política de Privacidade poderá ser atualizada em decorrência de eventual atualização normativa, razão pela qual se convida o usuário a consultar periodicamente esta seção.
</p>

<strong style={{textAlign:'justify', marginTop: '20px'}}>2. A quem compete as decisões referentes ao tratamento de dados pessoais realizado no Congresso UFCSPA</strong>
<p style={{textAlign:'justify', marginTop: '20px'}}>
A Lei Geral de Proteção de Dados Pessoais define como controlador, em seu artigo 5º:
<br/><br/>Art. 5º, VI – controlador: pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais;
<br/>
<br/>Para o Congresso UFCSPA, as decisões referentes ao tratamento de dados pessoais são de responsabilidade da UFCSPA.
<br/><br/>Endereço: Rua Sarmento Leite, 245 – Centro Histórico - CEP: 90050-170 Porto Alegre, RS, Brasil.
<br/><br/>E-mail: congressoufcspa@ufcspa.edu.br
</p>

<strong style={{textAlign:'justify', marginTop: '20px'}}>3. Quem realiza o tratamento de dados (Operador)?</strong>
<p style={{textAlign:'justify', marginTop: '20px'}}>
A Lei Geral de Proteção de Dados Pessoais define como operador, em seu artigo 5º:
<br/><br/>Art. 5º, VII - operador: pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento de dados pessoais em nome do controlador.
<br/><br/>Para o Congresso UFCSPA quem realiza o tratamento de dados pessoais em nome do controlador é o operador ENCONTRO DIGITAL TECNOLOGIA E EVENTOS LTDA, sob o CNPJ: 42.056.239/0001-23. Endereço: R. Estácio Pessoa, 50 - Cristo Redentor, Porto Alegre - RS, 91040-340
</p>

<strong style={{textAlign:'justify', marginTop: '20px'}}>4. Direitos do usuário</strong>
<p style={{textAlign:'justify', marginTop: '20px'}}>
Em conformidade com a LGPD, esta Política estabelece as seguintes diretrizes:
<br/><br/>- Os dados pessoais do usuário serão processados de forma lícita, leal e transparente (licitude, lealdade e transparência);
<br/><br/>- Os dados pessoais do usuário serão processados ou tratados de forma lícita, íntegra e transparente, tanto pelo controlador quanto por operadores devidamente autorizados; 
<br/>
<br/>- Os dados pessoais do usuário serão coletados apenas para finalidades determinadas, explícitas e legítimas, não podendo ser tratados posteriormente de uma forma incompatível com essas finalidades (limitação das finalidades);
<br/>
<br/>- Os dados pessoais do usuário serão coletados de forma adequada, pertinente e limitada às necessidades do objetivo para os quais eles são processados (minimização dos dados);
<br/>
<br/>- Os dados pessoais do usuário serão registrados com exatidão e clareza. A atualização dos dados será realizada ou os dados serão retificados quando possível para atendimento da finalidade a qual foram colhidos (exatidão);
<br/>
<br/>- Os dados pessoais do usuário serão conservados de forma que permita a identificação dos titulares dos dados apenas durante o período necessário para as finalidades para as quais são tratados (limitação da conservação);
<br/>
<br/>- Os dados pessoais do usuário serão tratados de forma segura, protegidos do tratamento não autorizado ou ilícito e contra a sua perda, destruição ou danificação acidental, adotando as medidas técnicas ou organizativas adequadas (integridade e confidencialidade).
<br/>
<br/>O usuário do site possui os seguintes direitos, conferidos pela Lei de Proteção de Dados Pessoais e pela LGPD:
<br/>
<br/>- Direito de confirmação e acesso: é o direito do usuário de obter do site a confirmação de que os dados pessoais que lhe digam respeito são ou não objeto de tratamento e, se for esse o caso, o direito de acessar os seus dados pessoais;
<br/>
<br/>- Direito de retificação: é o direito do usuário de obter do site, sem demora injustificada, a retificação dos dados pessoais inexatos que lhe digam respeito;
<br/>
<br/>- Direito à eliminação dos dados (direito ao esquecimento): é o direito do usuário de ter seus dados apagados do site;
<br/>
<br/>- Direito à limitação do tratamento dos dados: é o direito do usuário de limitar o tratamento de seus dados pessoais, podendo obtê-la quando contesta a exatidão dos dados, quando o tratamento for ilícito, quando o site não precisar mais dos dados para as finalidades propostas e quando tiver se oposto ao tratamento dos dados e em caso de tratamento de dados desnecessários;
<br/>
<br/>- Direito de oposição: é o direito do usuário de, a qualquer momento, se opor por motivos relacionados com a sua situação particular, ao tratamento dos dados pessoais que lhe digam respeito, podendo se opor ainda ao uso de seus dados pessoais para definição de perfil de marketing (profiling);
<br/>
<br/>- Direito de portabilidade dos dados: é o direito do usuário de receber os dados pessoais que lhe digam respeito e que tenha fornecido ao site, num formato estruturado, de uso corrente e de leitura automática, e o direito de transmitir esses dados a outro site;
<br/>
<br/>- Direito de não ser submetido a decisões automatizadas: é o direito do usuário de não ficar sujeito a nenhuma decisão tomada exclusivamente com base no tratamento automatizado, incluindo a definição de perfis (profiling), que produza efeitos na sua esfera jurídica ou que o afete significativamente de forma similar.
<br/>
<br/>O usuário poderá exercer os seus direitos por meio de comunicação escrita enviada via Plataforma Fala.BR na página www.ufcspa.edu.br/lgpd.
</p>

<strong style={{textAlign:'justify', marginTop: '20px'}}>5. Deveres do usuário</strong>
<p style={{textAlign:'justify', marginTop: '20px'}}>
<br/>Em conformidade com a LGPD, esta Política estabelece os seguintes deveres aos usuários:
<br/>
<br/><br/>O Usuário se responsabiliza pela precisão e pela veracidade dos dados informados no cadastro e reconhece que a inconsistência destes poderá implicar a impossibilidade de acessar o ambiente do Congresso.
<br/><br/>O login e senha só poderão ser utilizados pelo usuário cadastrado. Este deve manter o sigilo da senha, que é pessoal e intransferível, não sendo possível, em qualquer hipótese, a alegação de uso indevido, após o ato de compartilhamento.
<br/><br/>O usuário da Plataforma é responsável pela atualização das suas informações pessoais e pelas consequências de omissão ou erros nas informações pessoais cadastradas.
<br/><br/>O Usuário é responsável pela reparação de todo e qualquer dano, direto ou indireto (inclusive decorrente de violação de quaisquer direitos de outros usuários, de terceiros, abrangendo direitos de propriedade intelectual, de sigilo e de personalidade), que seja causado à UFCSPA, a qualquer outro usuário, ou, ainda, a terceiros, incluindo-se o dano em virtude do descumprimento do disposto nestes Termos de Uso e Política de Privacidade ou de qualquer ato praticado a partir de seu acesso à Internet, ao sítio e/ou Aplicativo.
<br/><br/>O usuário não deve interferir, comprometer ou interromper o serviço, tampouco os servidores ou as redes conectadas ao serviço, por meio da transmissão de qualquer malware, worm, vírus, spyware ou qualquer outro código de natureza destrutiva ou perturbadora. O usuário não pode inserir conteúdo ou códigos ou, de outra forma, alterar ou interferir na maneira como a página do serviço é exibida ou processada no dispositivo do usuário.
<br/><br/>A UFCSPA NÃO poderá ser responsabilizada pelos seguintes fatos:
<br/>a. Equipamento infectado ou invadido por atacantes;
<br/>b. Equipamento avariado no momento do consumo de serviços;
<br/>c. Proteção do dispositivo de acesso do usuário ao serviço;
<br/>d. Proteção das informações baseadas nos dispositivos de acesso dos usuários;
<br/>e. Abuso de uso dos dispositivos de acesso dos usuários;
<br/>f. Monitoração clandestina do dispositivo de acesso dos usuários;
<br/>g. Vulnerabilidades ou instabilidades existentes nos sistemas dos usuários;
<br/>h. Perímetro inseguro;
<br/><br/>O uso comercial das expressões utilizadas em aplicativos, tais como marca, nome empresarial ou nome de domínio, além dos conteúdos do serviço, assim como os programas, bancos de dados, redes e arquivos que permitem que o usuário acesse sua conta, estão protegidos pelas leis e tratados internacionais de direito autoral, marcas, patentes, modelos e desenhos industriais.
<br/><br/>Ao acessar o aplicativo, os usuários declaram que irão respeitar todos os direitos de propriedade intelectual e os decorrentes da proteção de marcas, patentes e/ou desenhos industriais, depositados ou registrados em, bem como todos os direitos referentes a terceiros que porventura estejam, ou estiverem de alguma forma, disponíveis no serviço. O simples acesso ao serviço não confere aos usuários qualquer direito ao uso dos nomes, títulos, palavras, frases, marcas, patentes, imagens, dados e informações, dentre outras, que nele estejam ou estiverem disponíveis.
<br/><br/>A reprodução de conteúdo descritos anteriormente está proibida, salvo com prévia autorização por escrito ou caso se destinem ao uso exclusivamente pessoal.
<br/><br/>Em nenhuma circunstância, os usuários adquirem qualquer direito sobre esses conteúdos.
<br/><br/>É vedada a utilização do serviço para finalidades comerciais, publicitárias ou qualquer outra que contrarie a finalidade para a qual foi concebido, conforme definido neste documento, sob pena de sujeição às sanções cabíveis na Lei nº 9.610/1998, que protege os direitos autorais no Brasil.
<br/><br/>Os visitantes e os usuários assumem toda e qualquer responsabilidade, de caráter civil e/ou criminal, pela utilização indevida das informações, textos, gráficos, marcas, imagens, enfim, todo e qualquer direito de propriedade intelectual ou industrial do serviço.
</p>


<strong style={{textAlign:'justify', marginTop: '20px'}}>6. Informações coletadas</strong>
<p style={{textAlign:'justify', marginTop: '20px'}}>
<br/>A coleta de dados dos usuários se dará em conformidade com o disposto nesta Política de Privacidade e dependerá do consentimento do usuário, sendo este dispensável somente nas hipóteses previstas no art. 11, inciso II, da Lei de Proteção de Dados Pessoais.
<br/>
<br/>Os dados pessoais serão coletados para fins de emissão de certificados e de envio de comunicados referentes ao Congresso, sendo os seguintes:
<br/>
<br/>Nome completo
<br/>CPF
<br/>Gênero
<br/>E-mail
<br/>Endereço completo
<br/>Deficiência*
<br/>Telefone celular
<br/>
<br/>*A solicitação de informação relativa a deficiência é justificada para o atendimento a eventuais necessidades específicas dos participantes.
<br/>
<br/>6.1. Dados informados no formulário de contato
<br/>Os dados eventualmente informados pelo usuário que utilizar o formulário de contato disponibilizado no site, incluindo o teor da mensagem enviada, serão coletados e armazenados.
<br/>
<br/>6.2 Dados relacionados à execução de contratos firmados com o usuário 
<br/>Para a execução de contrato de prestação de serviços eventualmente firmado entre o site e o usuário, poderão ser coletados e armazenados outros dados relacionados ou necessários a sua execução, incluindo o teor de eventuais comunicações tidas com o usuário.
<br/>
<br/>6.3 Registros de acesso
<br/>Em atendimento às disposições do art. 15, caput e parágrafos, da Lei Federal n. 12.965/2014 (Marco Civil da Internet), os registros de acesso do usuário serão coletados e armazenados por, pelo menos, seis meses.
<br/>
<br/>6.4. Dados sensíveis
<br/>Serão coletados dados sensíveis dos usuários relativos à saúde em conformidade com as definições conceituais da LGPD.  Os dados sensíveis coletados serão tratados com única finalidade de auxiliar, quando possível, as deficiências dos participantes do Congresso que assim se designarem na inscrição. O tratamento e processamento desses dados serão realizados com diligência e segurança exigida pela Lei Geral de Proteção de Dados Pessoais.
<br/>Serão coletados apenas dados sensíveis dos usuários relativos à saúde assim entendidos aqueles definidos nos arts. 9º e 10 do LGPD e nos arts. 11 e seguintes da Lei de Proteção de Dados Pessoais. São eles referentes a deficiência que eventualmente possa ser facilitada pela organização do Congresso.  Assim, dentre outros, não haverá coleta dos seguintes dados:
<br/>
<br/>- dados que revelem a origem racial ou étnica, as opiniões políticas, as convicções religiosas ou filosóficas, ou a filiação sindical do usuário;
<br/>
<br/>- dados genéticos;
<br/>
<br/>- dados biométricos para identificar uma pessoa de forma inequívoca;
<br/>
<br/>- dados relativos à vida sexual ou à orientação sexual do usuário;
<br/>
<br/>- dados relacionados a condenações penais ou a infrações ou com medidas de segurança conexas.
<br/>
<br/>6.5. Coleta de dados não previstos expressamente
<br/>Eventualmente, outros tipos de dados não previstos expressamente nesta Política de Privacidade poderão ser coletados, desde que sejam fornecidos com o consentimento do usuário, ou, ainda, que a coleta seja permitida ou imposta por lei.
<br/>
<br/>6.6. Fundamento jurídico para o tratamento dos dados pessoais
<br/>Ao utilizar os serviços do site do Congresso UFCSPA, o usuário está consentindo com a presente Política de Privacidade.
<br/>
<br/>O usuário tem o direito de retirar seu consentimento a qualquer momento, não comprometendo a licitude do tratamento de seus dados pessoais antes da retirada. A retirada do consentimento poderá ser feita via Plataforma Fala.BR na página www.ufcspa.edu.br/lgpd.
<br/>O consentimento dos relativamente ou absolutamente incapazes, especialmente de crianças menores de 16 (dezesseis) anos, apenas poderá ser feito, respectivamente, se devidamente assistidos ou representados.
<br/>
<br/>O tratamento de dados pessoais sem o consentimento do usuário apenas será realizado em razão de interesse legítimo ou para as hipóteses previstas em lei, ou seja, dentre outras, as seguintes:
<br/>
<br/>- para o cumprimento de obrigação legal ou regulatória pelo controlador;
<br/>
<br/>- para a realização de estudos por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;
<br/>
<br/>- quando necessário para a execução de contrato ou de procedimentos preliminares relacionados a contrato do qual seja parte o usuário, a pedido do titular dos dados;
<br/>
<br/>- para o exercício regular de direitos em processo judicial, administrativo ou arbitral, esse último nos termos da Lei nº 9.307, de 23 de setembro de 1996 (Lei de Arbitragem);
<br/>
<br/>- para a proteção da vida ou da incolumidade física do titular dos dados ou de terceiro;
<br/>
<br/>- para a tutela da saúde, em procedimento realizado por profissionais da área da saúde ou por entidades sanitárias;
<br/>
<br/>- quando necessário para atender aos interesses legítimos do controlador ou de terceiro, exceto no caso de prevalecerem direitos e liberdades fundamentais do titular dos dados que exijam a proteção dos dados pessoais;
<br/>
<br/>- para a proteção do crédito, inclusive quanto ao disposto na legislação pertinente.
<br/>
<br/>6.7. Finalidades do tratamento dos dados pessoais
<br/>Os dados pessoais do usuário coletados pelo site do Congresso UFCSPA têm por finalidade facilitar, agilizar e cumprir os compromissos estabelecidos com o usuário e a fazer cumprir as solicitações realizadas por meio do preenchimento de formulários.
<br/>
<br/>Os dados pessoais poderão ser utilizados também com uma finalidade para personalizar o conteúdo oferecido ao usuário, bem como para dar subsídio ao site para a melhora da qualidade e funcionamento de seus serviços.
<br/>
<br/>Os dados de cadastro serão utilizados para permitir o acesso do usuário a determinados conteúdos do site, exclusivos para usuários cadastrados.
<br/>
<br/>O tratamento de dados pessoais para finalidades não previstas nesta Política de Privacidade somente ocorrerá mediante comunicação prévia ao usuário, sendo que, em qualquer caso, os direitos e obrigações aqui previstos permanecerão aplicáveis.
<br/>
<br/>6.8. Prazo de conservação dos dados pessoais
<br/>Os dados pessoais do usuário serão conservados por um período não superior ao exigido para cumprir os objetivos em razão dos quais eles são processados.
<br/>
<br/>O período de conservação dos dados são definidos de acordo com os seguintes critérios:
<br/>
<br/>Os dados serão armazenados pelo tempo necessário para a prestação dos serviços fornecidos pelo site, que pode variar de 1 a 6 meses, de acordo com o status do pedido da pessoa.
<br/>
<br/>Os dados pessoais dos usuários apenas poderão ser conservados após o término de seu tratamento nas seguintes hipóteses:
<br/>
<br/>- para o cumprimento de obrigação legal ou regulatória pelo controlador;
<br/>
<br/>- para estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;
<br/>
<br/>- para a transferência de obrigação  a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos na legislação;
<br/>
<br/>- para uso exclusivo do controlador para o atendimento da finalidade apresentada nesse política, vedado seu acesso por terceiro, e desde que anonimizados os dados.
<br/>
<br/>6.9. Destinatários e compartilhamento dos dados pessoais
<br/><br/>A UFCSPA utiliza o serviço de instituições e empresas parceiras para a promoção e realização do evento. O cadastro das inscrições dos participantes do evento e a plataforma virtual de realização do Congresso estão sob a responsabilidade da empresa ENCONTRO DIGITAL TECNOLOGIA E EVENTOS LTDA, sob o CNPJ: 42.056.239/0001-23,  endereço: R. Estácio Pessoa, 50 - Cristo Redentor, Porto Alegre - RS, 91040-340. As finalidades da coleta dos dados pessoais dos participantes são a identificação do participante inscrito, o acesso às salas virtuais do congresso e a disponibilização de materiais aos participantes. Para a organização, promoção e apoio ao evento, a Fundação Médica do Rio Grande do Sul - FUNDMED, sob o CNPJ 94.391.901/0001-03, endereço: Rua Ramiro Barcelos, 2350, sala 117, Bom Fim, Porto Alegre - RS, 90035-003, poderá acessar os dados pessoais dos participantes inscritos através dos dados colhidos pela Encontro Digital, a fim de contatar os participantes que estiverem com dificuldades técnicas ou de acesso ao evento virtual. A FUNDMED também poderá utilizar os dados pessoais para análise de perfil dos inscritos e definir indicadores de resultados do evento. O tratamento, arquivamento e descarte dos dados pessoais pelas parceiras citadas estão em conformidade com a Lei Geral de Proteção de Dados Pessoais e devem ser descartadas ou anonimizadas ao fim da necessidade de atendimento dos usuários do Congresso.
<br/><br/>A UFCSPA como controladora, é responsável pelas decisões referentes ao tratamento de dados pessoais dos usuários de acordo com as exigências legais da LGPD, também aplicadas aos operadores dos dados deste evento, FUNDMED e Encontro Digital, sendo que o compartilhamento dos dados coletados pela Encontro Digital só podem ser feitos com a FUNDMED e UFCSPA.
</p>


<strong style={{textAlign:'justify', marginTop: '20px'}}>7. Do tratamento dos dados pessoais</strong>
<p style={{textAlign:'justify', marginTop: '20px'}}>
7.1. Do encarregado de proteção de dados (data protection officer)
<br/>O encarregado de proteção de dados (data protection officer) é o profissional encarregado de informar, aconselhar e controlar o responsável pelo tratamento dos dados e o processador de dados subcontratado, bem como os trabalhadores que tratem os dados, a respeito das obrigações do site nos termos da Lei de Proteção de Dados Pessoais e de outras disposições de proteção de dados presentes na legislação nacional e internacional, em cooperação com a autoridade de controle competente.
<br/>
<br/>Na UFCSPA o encarregado de proteção de dados (data protection officer) é o servidor Vinicius Lunkes Cezar, nomeado como encarregado pelo tratamento de dados da UFCSPA pela Portaria nº 60 da Reitoria. A abertura de manifestação sobre dados pessoais relacionados à UFCSPA deve ser feita pela Plataforma Fala.BR no site https://www.ufcspa.edu.br/lgpd.
</p>

<strong style={{textAlign:'justify', marginTop: '20px'}}>8. Segurança no tratamento dos dados pessoais do usuário</strong>
<p style={{textAlign:'justify', marginTop: '20px'}}>
O site se compromete a aplicar as medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, alteração, comunicação ou difusão de tais dados.
<br/>
<br/>Para a garantia da segurança, serão adotadas soluções que levem em consideração: as técnicas adequadas; os custos de aplicação; a natureza, o âmbito, o contexto e as finalidades do tratamento; e os riscos para os direitos e liberdades do usuário.
<br/>
<br/>O site utiliza certificado SSL (Secure Socket Layer) que garante que os dados pessoais se transmitam de forma segura e confidencial, de maneira que a transmissão dos dados entre o servidor web e navegadores, e em retroalimentação, ocorra de maneira totalmente cifrada ou encriptada.
<br/>
<br/>No entanto, a UFCSPA se exime de responsabilidade por culpa exclusiva de terceiro, como em caso de ataque de hackers ou crackers, ou culpa exclusiva do usuário, como no caso em que ele mesmo transfere seus dados a terceiro. O site se compromete a informar a UFCSPA em até 24 horas após identificado o incidente.
<br/>
<br/>A violação de dados pessoais é uma violação de segurança que provoque, de modo acidental ou ilícito, a destruição, a perda, a alteração, a divulgação ou o acesso não autorizado a dados pessoais transmitidos, conservados ou sujeitos a qualquer outro tipo de tratamento.
<br/>
Por fim, o site compromete- se a tratar os dados pessoais do usuário com confidencialidade, dentro dos limites legais.
</p>

<strong style={{textAlign:'justify', marginTop: '20px'}}>9. Dados de navegação (cookies)</strong>
<p style={{textAlign:'justify', marginTop: '20px'}}>
Cookies são pequenos arquivos de texto enviados pelo site ao computador do usuário e que nele ficam armazenados, com informações relacionadas à navegação do site.
<br/>
<br/>Por meio dos cookies, pequenas quantidades de informação são armazenadas pelo navegador do usuário para que nosso servidor possa lê-las posteriormente. Podem ser armazenados, por exemplo, dados sobre o dispositivo utilizado pelo usuário, bem como seu local e horário de acesso ao site.
<br/>
<br/>Os cookies não permitem que qualquer arquivo ou informação sejam extraídos do disco rígido do usuário, não sendo possível, ainda, que, por meio deles, se tenha acesso a informações pessoais que não tenham partido do usuário ou da forma como utiliza os recursos do site.
<br/>
<br/>É importante ressaltar que nem todo cookie contém informações que permitam a identificação do usuário, sendo que determinados tipos de cookies podem ser empregados simplesmente para que o site seja carregado corretamente ou para que suas funcionalidades funcionem do modo esperado.
<br/>
<br/>As informações eventualmente armazenadas em cookies que permitam identificar um usuário são consideradas dados pessoais. Dessa forma, todas as regras previstas nesta Política de Privacidade também lhes são aplicáveis.
<br/>
<br/>9.1. Cookies do site
<br/>Os cookies do site são aqueles enviados ao computador ou dispositivo do usuário e administrador exclusivamente pelo site.
<br/>
<br/>As informações coletadas por meio destes cookies são utilizadas para melhorar e personalizar a experiência do usuário, sendo que alguns cookies podem, por exemplo, ser utilizados para lembrar as preferências e escolhas do usuário, bem como para o oferecimento de conteúdo personalizado.
<br/>
<br/>9.2. Cookies de terceiros
<br/>Alguns de nossos parceiros podem configurar cookies nos dispositivos dos usuários que acessam nosso site.
<br/>
<br/>Estes cookies, em geral, visam possibilitar que nossos parceiros possam oferecer seu conteúdo e seus serviços ao usuário que acessa nosso site de forma personalizada, por meio da obtenção de dados de navegação extraídos a partir de sua interação com o site.
<br/>
<br/>O usuário poderá obter mais informações sobre os cookies de terceiro e sobre a forma como os dados obtidos a partir dele são tratados, além de ter acesso à descrição dos cookies utilizados e de suas características, acessando o seguinte link:
<br/>
<br/>Google Analytics
<br/>
<br/>https://developers.google.com/analytics/devguides/collection/gajs/cookie-usage
<br/>
<br/>As entidades encarregadas da coleta dos cookies poderão, de acordo com a política de privacidade das organizações, ceder as informações obtidas a terceiros.
<br/>
<br/>9.3. Cookies de redes sociais
<br/>O site utiliza plugins de redes sociais, que permitem acessá-las a partir do site. Assim, ao fazê-lo, os cookies utilizados por elas poderão ser armazenados no navegador do usuário.
<br/>
<br/>Cada rede social possui sua própria política de privacidade e de proteção de dados pessoais, sendo as pessoas físicas ou jurídicas que as mantêm responsáveis pelos dados coletados e pelas práticas de privacidade adotadas.
<br/>
<br/>O usuário pode pesquisar, junto às redes sociais, informações sobre como seus dados pessoais são tratados. A título informativo, disponibilizamos os seguintes links, a partir dos quais poderão ser consultadas as políticas de privacidade e de cookies adotadas por algumas das principais redes sociais:
<br/>
<br/>Facebook: https://www.facebook.com/policies/cookies/
<br/>
<br/>Twitter: https://twitter.com/pt/privacy
<br/>
<br/>Instagram: https://help.instagram.com/1896641480634370?ref=ig
<br/>
<br/>Youtube: https://policies.google.com/privacy?hl=pt-BR&gl=pt
<br/>
<br/>LinkedIn: https://www.linkedin.com/legal/cookie-policy?trk=hp-cookies
<br/>
<br/>9.4. Gestão dos cookies e configurações do navegador
<br/>O usuário poderá se opor ao registro de cookies pelo site, bastando que desative esta opção no seu próprio navegador ou aparelho.
<br/>
<br/>A desativação dos cookies, no entanto, pode afetar a disponibilidade de algumas ferramentas e funcionalidades do site, comprometendo seu correto e esperado funcionamento. Outra consequência possível é remoção das preferências do usuário que eventualmente tiverem sido salvas, prejudicando sua experiência.
<br/>
<br/>A seguir, são disponibilizados alguns links para as páginas de ajuda e suporte dos navegadores mais utilizados, que poderão ser acessadas pelo usuário interessado em obter mais informações sobre a gestão de cookies em seu navegador:
<br/>
<br/>Internet Explorer:
<br/>https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies
<br/>
<br/>Safari:
<br/>https://support.apple.com/pt-br/guide/safari/sfri11471/mac
<br/>
<br/>Google Chrome:
<br/>https://support.google.com/chrome/answer/95647?hl=pt-BR&hlrm=pt
<br/>
<br/>Mozila Firefox:
<br/>https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam
<br/>
<br/>Opera:
<br/>https://www.opera.com/help/tutorials/security/privacy/
</p>

<strong style={{textAlign:'justify', marginTop: '20px'}}>10. Reclamação a uma autoridade de controle</strong>
<p style={{textAlign:'justify', marginTop: '20px'}}>
Sem prejuízo de qualquer outra via de recurso administrativo ou judicial, todos os titulares de dados têm direito a apresentar reclamação à Autoridade Nacional de Proteção de Dados. A reclamação poderá ser feita à autoridade da sede do site, do país de residência habitual do usuário, do seu local de trabalho ou do local onde foi alegadamente praticada a infração.
</p>


<strong style={{textAlign:'justify', marginTop: '20px'}}>11. Das alterações</strong>
<p style={{textAlign:'justify', marginTop: '20px'}}>
A presente versão desta Política de Privacidade foi atualizada pela última vez em: 24/08/2021.
<br/>
<br/>O editor se reserva o direito de modificar, a qualquer momento e sem qualquer aviso prévio, no site as presentes normas, especialmente para adaptá-las às evoluções do site Encontro Digital, seja pela disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já existentes.
<br/>
<br/>Dessa forma, convida-se o usuário a consultar periodicamente esta página para verificar as atualizações.
<br/>
<br/>Ao utilizar o serviço após eventuais modificações, o usuário demonstra sua concordância com as novas normas. Caso discorde de alguma das modificações, deverá pedir, imediatamente, o cancelamento de sua conta e apresentar a sua ressalva ao serviço de atendimento, se assim o desejar.
</p>

<strong style={{textAlign:'justify', marginTop: '20px'}}>12. Do Direito aplicável e do foro</strong>
<p style={{textAlign:'justify', marginTop: '20px'}}>
Para a solução das controvérsias decorrentes do presente instrumento, será aplicado integralmente o Direito brasileiro.
<br/>
<br/>Os eventuais litígios deverão ser apresentados no foro da comarca em que se encontra a sede do editor do site.</p>

        </div>
      </ContainerWhite>
    </>
  );
};
export default Termos;
